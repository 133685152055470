import 'react-hot-loader';
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ReactGA from 'react-ga';

import { routerAppComponentDidMount } from '@actions/router.actions';
import { authLogoutRequest } from '@actions/auth.actions';
import { notificationsHide } from '@actions/notifications.actions';
import Loading from '@components/loading/loading';
import { getIsMobile } from '@selectors/global.selectors';
import { isManager, getProfile } from '@selectors/profile.selectors';
import { getShownNotifications } from '@selectors/notifications.selectors';
import { PrivateRoute, LoginContainer } from '@routes/auth';
import { coursesCourseFinished } from '@actions/courses.actions';

import { getActiveCourse } from '@selectors/courses.selectors';

import {
  employees as employeesRoutes,
  courseCatalog as courseCatalogRoutes,
  myEducation as myEducationRoutes,
} from '@routes/routes.manifest';

import '@styles/app.scss';
import CoursePlayer from '@routes/course-catalog/components/course-player/course-player';

import { MyEducationSwitch } from '@routes/my-education';
import CourseCatalogSwitch from '@routes/course-catalog/course-catalog-switch';
import { EmployeesContainer } from '@routes/employees';
import CampExtra from '@routes/camp-extra/camp-extra';
import Report from '@routes/reports/report';

import Menu from '@components/menu/menu';
import Notifications from '@components/notifications/notifications';
import '@styles/foundation.min.css';

import Cookies from 'js-cookie';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronRight,
  faThumbsUp,
  faPlus,
  faUser,
  faCalendar,
  faCalendarAlt,
  faHourglass,
  faChevronUp,
  faUserCheck,
  faChevronDown,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faChevronRight,
  faThumbsUp,
  faUser,
  faPlus,
  faHourglass,
  faCalendar,
  faCalendarAlt,
  faUserCheck,
  faChevronUp,
  faChevronDown,
  faFileExcel
);

import { hot } from 'react-hot-loader/root';
import { messagesGetMessages } from '@actions/messages.actions';

import { version, buildHash } from '@src/config';
import SignatureModal from '@components/course/signature-modal';

import styled from 'styled-components';
import ClearCache from '@components/clear-cache/clear-cache';

const NoticeBanner = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 999999;
  padding: 20px 0;
  padding-bottom: 4px;
  line-height: 1.5;
  border-top-right-radius: 10px,
  border-top-left-radius: 10px,
  font-size: 1.0rem;
  font-weight: bold;
  text-align: center;

  background-color: var(--color-header-top);
  color: var(--color-header-text) !important;

  span {
    display: inline-block;
    margin-bottom: 16px;
  }

  .btn {
    display: inline-block;
    margin: -0.25em 20px;
    margin-bottom: 16px;
    padding: 0 20px;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.75;
    border: 1px solid white;
    background: var(--color-main-dark);
  }
`;

class App extends Component {
  componentDidMount() {
    const { routerAppComponentDidMount, messagesGetMessages } = this.props;

    this.onEndCourseClick = this.onEndCourseClick.bind(this);
    routerAppComponentDidMount();

    ReactGA.initialize('UA-110810743-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.messagesFetch = setInterval(() => {
      messagesGetMessages();
    }, 360000);
  }

  componentWillUnmount() {
    clearInterval(this.messagesFetch);
  }

  onEndCourseClick() {
    const { coursesCourseFinished, activeCourse } = this.props;
    /*

    /my-education/role/365

    */
    let my_section = 'first-page';
    if (window.location.pathname.indexOf('/role') !== -1) {
      my_section = 'roles';
    }
    coursesCourseFinished({ cid: activeCourse.cid, section: my_section });
  }

  messagesFetch;

  render() {
    const {
      sessionId,
      isMobile,
      profile,
      isManager,
      alert,
      activeCourse,
      authLogoutRequest,
      notifications,
      secondLogin,
      hideNotification,
    } = this.props;

    return (
      <Router basename={process.env.REACT_APP_MOUNT_PATH}>
        <div className="app-wrapper coop">
          {((!Cookies.get('tg-visit') ||
            !sessionId ||
            secondLogin ||
            profile.error) && (
            <div className="app-wrapper__content">
              <LoginContainer />
              <Redirect from="*" to="/login" />
            </div>
          )) || (
            <>
              <div className="app-wrapper__menu">
                <Menu
                  isManager={isManager}
                  onLogoutClick={() => {
                    authLogoutRequest();
                  }}
                  profileData={profile.data}
                />
              </div>
              <div className="app-wrapper__content">
                {activeCourse && activeCourse.status !== 0 && (
                  <CoursePlayer
                    reloading={alert.type === 'alert-reloading'}
                    onEnd={this.onEndCourseClick}
                    id={activeCourse.cid}
                    type={activeCourse.type}
                    url={activeCourse.url}
                    opened={activeCourse.opened}
                  />
                )}

                <Switch>
                  {!Cookies.get('tg-visit') ||
                    (profile.error && <LoginContainer />)}
                  <PrivateRoute
                    path={courseCatalogRoutes.main.path}
                    component={CourseCatalogSwitch}
                  />
                  <PrivateRoute
                    path={myEducationRoutes.main.path}
                    component={MyEducationSwitch}
                  />
                  <PrivateRoute
                    path={employeesRoutes.campExtra}
                    component={CampExtra}
                  />
                  <PrivateRoute
                    path={[employeesRoutes.report, employeesRoutes.reportNew]}
                    component={Report}
                  />
                  <PrivateRoute
                    path={Object.values(employeesRoutes)}
                    component={EmployeesContainer}
                  />
                  <Redirect to="/my-education" />
                </Switch>
                <SignatureModal />
                <Notifications
                  notifications={notifications}
                  onHideNotification={hideNotification}
                />
                {(version || buildHash) && (
                  <>
                    {(isMobile && (
                      <div
                        style={{
                          left: '10px',
                          bottom: '10px',
                          opacity: '0.3',
                          padding: '10px',
                          fontSize: '0.8rem',
                        }}>
                        {`${buildHash} v. ${version}`}
                      </div>
                    )) || (
                      <div
                        style={{
                          left: '10px',
                          bottom: '10px',
                          position: 'fixed',
                          opacity: '0.3',
                          padding: '10px',
                          fontSize: '0.8rem',
                        }}>
                        {`${buildHash} v. ${version}`}
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
          {alert.type === 'alert-reloading' && <Loading />}
          <ClearCache>
            {({ message, isLatestVersion, emptyCacheStorage }) =>
              !isLatestVersion && (
                <NoticeBanner>
                  <span>
                    {message ||
                      'Ny versjon av Læringsportalen er lansert. Klikk refresh for ny versjon.'}
                  </span>
                  <button
                    type="button"
                    className="btn"
                    zIndex="0"
                    onClick={() => {
                      emptyCacheStorage();
                    }}>
                    REFRESH
                  </button>
                </NoticeBanner>
              )
            }
          </ClearCache>
        </div>
      </Router>
    );
  }
}

App.propTypes = {
  sessionId: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  profile: PropTypes.shape({}).isRequired,
  activeCourse: PropTypes.shape({}),
  isManager: PropTypes.bool.isRequired,
  secondLogin: PropTypes.bool.isRequired,
  coursesCourseFinished: PropTypes.func.isRequired,
  alert: PropTypes.shape({}).isRequired,
  notifications: PropTypes.shape({}).isRequired,
  routerAppComponentDidMount: PropTypes.func.isRequired,
  authLogoutRequest: PropTypes.func.isRequired,
  messagesGetMessages: PropTypes.func.isRequired,
  hideNotification: PropTypes.func.isRequired,
};

App.defaultProps = {
  sessionId: undefined,
  activeCourse: null,
};

const mapStateToProps = state => ({
  sessionId: state.auth.sessionId,
  secondLogin: state.auth.secondLogin,
  alert: state.alert,
  activeCourse: getActiveCourse(state),
  profile: getProfile(state),
  notifications: getShownNotifications(state),
  isManager: isManager(state),
  isMobile: getIsMobile(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerAppComponentDidMount,
      authLogoutRequest,
      coursesCourseFinished,
      messagesGetMessages,
      hideNotification: notificationsHide,
    },
    dispatch
  );

export default hot(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
