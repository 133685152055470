import { backendUrl } from '@config';
import axios from 'axios';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  COMPETENCES_TOGGLE,
  competencesToggleRequest,
  competencesToggleSuccess,
  competencesToggleFailure,
} from '@actions/competences.actions';


import { profileUpdateOneCompetences } from '@actions/profile.actions';
import { notificationsAdd } from '@actions/notifications.actions';

import {
  employeesFetchChecklists,
  employeesFetchSelectedPersonChecklists,
} from '@actions/employees.actions';

import { getOrganisationId, getProfileUserName } from '@selectors/profile.selectors';
import { getSelectedPersonUsername } from '../selectors/employees.selectors';

function* toggleCompetence(payload) {
  console.log('set it****');

  yield put(competencesToggleRequest());
  const organisationId = yield select(getOrganisationId);
  try {
    const { cid, pid, size } = payload.payload;
    console.log('my size');
    console.log(size);
    if (size === 'dashboard') {
      /*
      THIS IS EMPLOYEE, SO IT IS MANAGER THAT DOES THIS.
       */
      const action_name = `checklist_item_manager_${cid}`;
      yield call(axios.request, {
        method: 'POST',
        url: `${backendUrl}/courses/setchecklistitem`,
        params: {
          name: action_name,
          value: true,
          person_id: pid,
        },
        withCredentials: true,
      });
      if (window.location.pathname.indexOf('/dashboard') !== -1) {
        yield put(employeesFetchChecklists());
      } else {
        const userName = yield select(getSelectedPersonUsername);
        yield put(employeesFetchSelectedPersonChecklists({ userName, organisationId }));
      }

      yield put(
        notificationsAdd({
          notification: {
            text: 'Sjekkliste er oppdatert.',
            color: 'green',
          },
        })
      );
    } else {
      const userName = yield select(getProfileUserName);
      yield call(axios.request, {
        method: 'POST',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          competence_id: cid,
          user_name: userName,
          passed: 100,
          action: 'on',
        },
        withCredentials: true,
      });
      yield put(
        notificationsAdd({
          notification: {
            text: 'Sjekkliste er oppdatert.',
            color: 'green',
          },
        }));
      yield put(profileUpdateOneCompetences());

    }
    yield put(competencesToggleSuccess());
    /*
    yield put(
      fetchCompetencesChildren({
        courseId: 30,
        personId: profileId,
        orgId: organisationId,
        root: 'show-role',
      })
    );
    */
  } catch (error) {
    yield put(competencesToggleFailure({ error }));
  }
}


export default [
  takeLatest(COMPETENCES_TOGGLE, toggleCompetence),
];
