import React from 'react';
import PropTypes from 'prop-types';

import CourseDetails from '@components/course/course-details';

const CoursePreviewContainer = ({ match }) => (
  <div className="my-education">
    <div className="my-education__section">
      <CourseDetails
        roleId={match.params.roleId}
        courseId={match.params.cid}
        rootId={match.params.cid}
      />
    </div>
  </div>
);

CoursePreviewContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      roleId: PropTypes.shape.isRequired,
      courseId: PropTypes.shape.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CoursePreviewContainer;
