import React, { useState, useEffect, useRef, useCallback } from 'react';
import ProgressbarChart from '@components/progressbar-chart/progressbar-chart';
import './extra-employees-chart.scss';
import classNames from 'classnames';

const ExtraEmployeesChart = ({ ...props }) => {
  const { style, className } = props;

  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const wrapperRef = useRef();

  const setSize = useCallback(() => {
    if (wrapperRef.current) {
      const { width } = wrapperRef.current.getBoundingClientRect();
      setWidth(width);
    }
  }, [wrapperRef.current]);

  useEffect(() => {
    window.addEventListener('resize', setSize);
    window.addEventListener('orientationchange', setSize);
    setSize();

    return () => {
      window.removeEventListener('resize', setSize);
      window.removeEventListener('orientationchange', setSize);
    };
  }, [setSize]);

  return (
    <div
      ref={wrapperRef}
      className={classNames(className, 'extra-employees-chart')}
      style={{
        ...(style || {}),
        height,
      }}>
      <ProgressbarChart
        {...props}
        width={width}
        height={height}
        onLayoutHeight={setHeight}
      />
    </div>
  );
};

export default ExtraEmployeesChart;
