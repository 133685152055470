import PropTypes from 'prop-types';
import React from 'react';
import './course-duration.scss';
import classNames from 'classnames';

const clockFAClass = 'fas fa-clock';
const stopwatchFAClass = 'fas fa-stopwatch';

const getDurationName = (metric, plural) =>
  (({
    hours: ['time', 'timer'],
    days: ['dag', 'dager'],
    minutes: ['minutt', 'minutter'],
  }[metric] || [])[(plural && 1) || 0] || metric);

const CourseDuration = ({
  duration,
  metric,
  iconType,
  className,
  ...props
}) => {
  return (
    <div className={classNames('course-duration', className)} {...props}>
      <i
        className={classNames(
          'course-duration__clock',
          iconType === 'stopwatch' ? stopwatchFAClass : clockFAClass
        )}
        title="varighet"
      />
      {`${duration} ${getDurationName(metric, duration !== 1)}`}
    </div>
  );
};

CourseDuration.defaultProps = {
  className: null,
  iconType: 'clock',
};

CourseDuration.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.number.isRequired,
  metric: PropTypes.oneOf(['hours', 'days', 'minutes']).isRequired,
  iconType: PropTypes.oneOf(['stopwatch', 'clock']),
};

export default CourseDuration;
