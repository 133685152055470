import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  getProfileId,
  getOrganisationId,
  getMessageUnreadCount,
} from '@selectors/profile.selectors';
import { getRouteWithParams } from '@utils/routes.utils';
import {
  employees as employeeRoutes,
  myEducation,
} from '@routes/routes.manifest';

import { withRouter, matchPath } from 'react-router';
import { getIsMobile } from '@selectors/global.selectors';
import DesktopMenu from './desktop-menu/desktop-menu';
import MobileMenu from './mobile-menu/mobile-menu';

const Menu = ({
  onLogoutClick,
  isManager,
  isMobile,
  orgId,
  personId,
  profileData,
  messagesCount,
  location,
}) => {
  const [isMobileMenuOpened, setMobileMenuOpened] = useState(false);

  const toggleSidebarMenu = () => {
    setMobileMenuOpened(!isMobileMenuOpened);
  };

  const messagesCountString = (messagesCount && ` (${messagesCount})`) || '';
  const menuLinksManager = [
    {
      name: 'Min side',
      title: 'Min Side',
      path: '/my-education',
      active: matchPath(location.pathname, {
        path: '/my-education',
        exact: false,
      }),
      children: [
        {
          name: `Meldinger${messagesCountString}`,
          title: 'Meldinger - Min Side',
          path: getRouteWithParams(myEducation.messagesView.path, { personId }),
          active: matchPath(location.pathname, {
            path: myEducation.messagesView.active,
            exact: false,
          }),
        },
        {
          name: `Gjennomført opplæring`,
          title: 'Gjennomført opplæring - Min Side',
          path: getRouteWithParams(myEducation.cvView.path, { personId }),
          active: matchPath(location.pathname, {
            path: myEducation.cvView.active,
            exact: false,
          }),
        },
      ],
    },
    {
      name: 'Kurskatalog',
      title: 'Kurskatalog - Min Side',
      path: '/course-catalog',
      active: matchPath(location.pathname, {
        path: '/course-catalog',
        exact: false,
      }),
    },
    {
      name: 'Dashboard',
      title: 'Dashboard - Min Side',
      path: getRouteWithParams(employeeRoutes.dashboard, { orgId }),
      active: matchPath(location.pathname, {
        path: '/dashboard',
        exact: false,
      }),
    },
    {
      name: 'Ansatte',
      title: 'Ansatte - Min Side',
      path: getRouteWithParams(employeeRoutes.employees, { orgId }),
      active: matchPath(location.pathname, {
        path: '/employees',
        exact: false,
      }),
    },
  ];

  const menuLinksEmployee = [
    {
      name: 'Min side',
      path: '/my-education',
      active: matchPath(location.pathname, {
        path: '/my-education',
        exact: false,
      }),
      children: [
        {
          name: `Meldinger${messagesCountString}`,
          path: getRouteWithParams(myEducation.messagesView.path, { personId }),
          active: matchPath(location.pathname, {
            path: myEducation.messagesView.active,
            exact: false,
          }),
        },
        {
          name: `Gjennomført opplæring`,
          path: getRouteWithParams(myEducation.cvView.path, { personId }),
          active: matchPath(location.pathname, {
            path: myEducation.cvView.active,
            exact: false,
          }),
        },
      ],
    },
    {
      name: 'Kurskatalog',
      path: '/course-catalog',
      active: matchPath(location.pathname, {
        path: '/course-catalog',
        exact: false,
      }),
    },
  ];

  const menuLinks = isManager ? menuLinksManager : menuLinksEmployee;

  const setDocumentTitle = links => {
    return (
      links &&
      links.map(({ title, active, children }) => {
        if (active && title) {
          document.title = title;
        }
        return setDocumentTitle(children);
      })
    );
  };

  setDocumentTitle(menuLinksManager);

  return (
    (isMobile && (
      <MobileMenu
        menuLinks={menuLinks}
        orgId={orgId}
        isSidebarOpen={isMobileMenuOpened}
        onLogoutClick={onLogoutClick}
        onToggleSidebarClick={toggleSidebarMenu}
        onMenuItemClick={toggleSidebarMenu}
        profile={profileData}
      />
    )) || (
      <DesktopMenu
        orgId={orgId}
        menuLinks={menuLinks}
        onLogoutClick={onLogoutClick}
      />
    )
  );
};

Menu.propTypes = {
  onLogoutClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  profileData: PropTypes.shape({}),
  orgId: PropTypes.number.isRequired,
  personId: PropTypes.number.isRequired,
  messagesCount: PropTypes.number.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

Menu.defaultProps = {
  profileData: null,
};

const mapStateToProps = state => ({
  orgId: getOrganisationId(state),
  personId: getProfileId(state),
  messagesCount: getMessageUnreadCount(state),
  isMobile: getIsMobile(state),
});

export default withRouter(connect(mapStateToProps)(Menu));
