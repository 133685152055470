import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Modal from '@components/modal/modal';
import FormMultiselect from '@components/form-multiselect';

import './course-sign-button.scss';
import {
  getSigningOnCourse,
  getSignOnCourseResults,
} from '@selectors/courses.selectors';
import { isManager } from '@selectors/profile.selectors';
import {
  getEmployees,
  getIsFetchingEmployees,
} from '@selectors/employees.selectors';
import { employeesGet } from '@actions/employees.actions';
import { courseSignOn } from '@actions/courses.actions';
import SearchBar from '@routes/employees/components/search-bar';

const validateNotEmpty = input => {
  if (!input || !input.length || (input.length === 1 && input[0] === '')) {
    return 'Du må velge en ansatt fra listen for å melde på';
  }

  return undefined;
};

const ModalForm = reduxForm({
  form: 'register-employees',
})(({ handleSubmit, onSubmit, onSearchChange, onCancel, employees }) => (
  <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
    <div className="course-sign__search-wrapper">
      <SearchBar
        placeholder="Søk..."
        name="search-bar"
        style={{ fontSize: '15px', paddingTop: '5px', paddingBottom: '5px' }}
        loading={employees.isFetching}
        onChange={({ target: { value } }) => {
          onSearchChange(value);
        }}
      />
    </div>
    <Field
      className="modal-form__multiselect"
      component={FormMultiselect}
      name="employees"
      data={employees.data.map(({ fullname, user_name }) => ({
        id: user_name,
        title: fullname,
      }))}
      busy={employees.isFetching}
      messages={{
        emptyList: 'Ingen ansatte å velge',
      }}
      validate={[validateNotEmpty]}
    />
    <div className="modal-form__buttons">
      <button type="button" className="btn btn--flat" onClick={onCancel}>
        Avbryt
      </button>
      <button type="submit" className="btn">
        Meld på
      </button>
    </div>
  </form>
));

const CourseSignButton = ({
  courseEvent,
  courseSignOn,
  employeesData: { data: employeesData },
  isFetchingEmployees,
  isBusy,
  employeesGet,
  usersCourseEvent,
  withDropdown,
  signingResults,
}) => {
  const initialResults = {
    results: {
      correct: [],
      errors: [],
    },
    isFetching: false,
  };

  const [open, setOpen] = useState(false);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [{ results, isFetching }, setLocalResults] = useState(initialResults);
  const [searchPhraze, setSearchPhraze] = useState('');

  const employees = {
    isFetching: isFetchingEmployees,
    data: employeesData,
  };

  useEffect(() => {
    setLocalResults(signingResults);
  }, [signingResults.results]);

  const { id: courseId } = courseEvent;

  const closeModal = () => {
    setModalVisibility(false);
    setShowResults(false);
    setLocalResults(initialResults);
  };

  const onSignOnCourseClick = (ceid, employees) => {
    courseSignOn({
      courseEventId: ceid,
      employees,
    });
    setOpen(false);
  };

  const onSignEmployeesConfirm = ({ employees: selectedEmployees }) => {
    const registeredEmployees =
      selectedEmployees &&
      selectedEmployees.reduce((acc, cur) => {
        const employee = employees.data.find(
          ({ user_name }) => user_name === cur.id
        );

        if (employee) {
          acc.push(employee);
        }

        return acc;
      }, []);

    if (registeredEmployees) {
      courseSignOn({
        courseEventId: courseId,
        employees: registeredEmployees,
      });
    }

    setOpen(false);
    setShowResults(true);
  };

  const onSearchChange = value => {
    setSearchPhraze(value);
  };

  return (
    <div className="course-sign 2">
      {withDropdown ? (
        <>
          <div className="course-sign__dropdown">
            <button
              type="button"
              className={classNames([
                'btn',
                'course-sign__button',
                'course-sign__dropdown-btn',
                'course-sign__dropdown-btn--main',
              ])}
              onClick={() => setOpen(!open)}
              onKeyPress={() => setOpen(!open)}
              onBlur={() => setOpen(false)}>
              {isBusy ? '...' : 'Meld på'}
            </button>
            <button
              type="button"
              className={classNames([
                'btn',
                'course-sign__button',
                'course-sign__dropdown-btn',
                'course-sign__dropdown-btn--chevron',
              ])}
              onClick={() => setOpen(!open)}
              onKeyPress={() => setOpen(!open)}
              onBlur={() => setOpen(false)}>
              <i
                className={classNames([
                  'fas',
                  { 'fa-chevron-down': !open },
                  { 'fa-chevron-up': open },
                ])}
              />
            </button>
            {open && (
              <div className="course-sign__dropdown-options">
                <button
                  className="course-sign__dropdown-option"
                  type="button"
                  onMouseDown={() => {
                    setModalVisibility(true);
                    employeesGet({ all: true });
                  }}>
                  <i className="fas fa-plus" />
                  <span>Meld på ansatte</span>
                </button>
                {(usersCourseEvent && usersCourseEvent.length !== 0 && (
                  <button
                    className="course-sign__dropdown-option"
                    type="button">
                    <i className="fas fa-check" />
                    <span>Du er påmeldt</span>
                  </button>
                )) || (
                  <button
                    className="course-sign__dropdown-option"
                    type="button"
                    onMouseDown={() => {
                      onSignOnCourseClick(courseId);
                      setOpen(false);
                    }}>
                    <i className="fas fa-plus" />
                    <span>Meld på meg</span>
                  </button>
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <button
          type="button"
          className="btn course-sign__button"
          onClick={() => {
            onSignOnCourseClick(courseId);
          }}>
          {isBusy ? '...' : 'Meld på'}
        </button>
      )}
      {isModalVisible && (
        <Modal onBackdropClick={closeModal}>
          <div className="course-sign__modal">
            <h2 className="course-sign__modal-title">Meld ansatte på kurs</h2>
            {employees.isFetching && <span>Loading...</span>}
            {employees.data && !showResults && (
              <ModalForm
                employees={
                  searchPhraze !== ''
                    ? {
                        ...employees,
                        data: employees.data.filter(ed =>
                          ed.fullname
                            .toLowerCase()
                            .includes(searchPhraze.toLowerCase())
                        ),
                      }
                    : employees
                }
                onSubmit={onSignEmployeesConfirm}
                onSearchChange={onSearchChange}
                onCancel={closeModal}
              />
            )}
            {showResults && isFetching && <span>Loading...</span>}
            {showResults && !isFetching && (
              <div className="course-sign__modal-results">
                {results.correct && results.correct.length > 0 && (
                  <div className="course-sign__modal-results__container">
                    <h4 className="course-sign__modal-subtitle">
                      Disse personer ble meldt på
                    </h4>
                    {results.correct.map(({ user }) => (
                      <div
                        key={`course-signon-error-${user}`}
                        className={classNames([
                          'course-sign__modal-results__item',
                          'course-sign__modal-results__item--correct',
                        ])}>
                        <i className="fas fa-check" />
                        <span>{user}</span>
                      </div>
                    ))}
                  </div>
                )}
                {results.errors && results.errors.length > 0 && (
                  <div className="course-sign__modal-results__container">
                    <h4 className="course-sign__modal-subtitle">
                      Disse peronerne ble ikke meldt på
                    </h4>
                    {results.errors.map(({ user }) => (
                      <div
                        key={`course-signon-error-${user}`}
                        className={classNames([
                          'course-sign__modal-results__item',
                          'course-sign__modal-results__item--error',
                        ])}>
                        <i className="fas fa-times" />
                        <span>{user}</span>
                      </div>
                    ))}
                  </div>
                )}
                <div className="modal-form__buttons">
                  <button
                    className="btn course-sign__button"
                    type="button"
                    onClick={closeModal}>
                    OK
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

CourseSignButton.propTypes = {
  courseEvent: PropTypes.shape({}).isRequired,
  courseSignOn: PropTypes.func.isRequired,
  usersCourseEvent: PropTypes.array.isRequired,
  employeesData: PropTypes.shape.isRequired,
  isBusy: PropTypes.bool,
  isFetchingEmployees: PropTypes.bool,
  employeesGet: PropTypes.func.isRequired,
  signingResults: PropTypes.arrayOf(
    PropTypes.shape({
      correct: PropTypes.arrayOf({
        message: PropTypes.string,
        user: PropTypes.string,
      }),
      errors: PropTypes.arrayOf({
        message: PropTypes.string,
        user: PropTypes.string,
      }),
    })
  ),
  withDropdown: PropTypes.bool,
};

CourseSignButton.defaultProps = {
  withDropdown: false,
  isBusy: false,
  isFetchingEmployees: false,
  signingResults: [],
};

const mapStateToProps = state => ({
  courseSigningOn: getSigningOnCourse(state),
  withDropdown: isManager(state),
  employeesData: getEmployees(state),
  isFetchingEmployees: getIsFetchingEmployees(state),
  signingResults: getSignOnCourseResults(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      courseSignOn,
      employeesGet,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseSignButton);
