import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { fetchedDataWrapperShape } from '@types/fetched-data';

import {
  getCompetencegroups,
  getSelectedCompetencegroupId,
  getSelectedSubcompetencegroupId,
  getSelectedSubSubcompetencegroupId,
  getCoursesSorting,
} from '@selectors/courses.selectors';

import {
  coursesFiltersSetSubcompetencegroup,
  coursesFiltersSetSubSubcompetencegroup,
  coursesSetSorting,
} from '@actions/courses.actions';

import './courses-menu.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import CoursesMenuChipses from '@routes/course-catalog/containers/course-catalog/components/courses-menu-chipses/courses-menu-chipses';

const sorting_options = [
  ['alpha', 'A-Å'],
  ['date', 'Dato'],
  ['duration', 'Varighet'],
];

const CoursesMenu = ({
  competencegroups,
  selectedCompetencegroupId,
  selectedSubcompetencegroupId,
  selectedSubSubcompetencegroupId,
  coursesFiltersSetSubcompetencegroup,
  coursesFiltersSetSubSubcompetencegroup,
  coursesSetSorting,
  isMobile,
  sorting,
}) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [subSubOpen, setSubSubOpen] = useState(false);

  const handleCompetenceChipsClick = competencegroupId => {
    coursesFiltersSetSubcompetencegroup({
      subcompetencegroupId: competencegroupId,
    });
  };

  const handleSorting = sorting => {
    setFiltersOpen(false);
    setSubSubOpen(false);
    coursesSetSorting(sorting);
  };

  const handleSortingClick = () => {
    setFiltersOpen(!filtersOpen);
    setSubSubOpen(false);
  };

  const handleSubSubButtonClick = () => {
    setSubSubOpen(!subSubOpen);
  };

  const subCompetencegroups =
    selectedCompetencegroupId &&
    Array.isArray(competencegroups.data) &&
    competencegroups.data.find(
      competencegroup => competencegroup.id === selectedCompetencegroupId
    ).children;

  const subSubCompetencegroups =
    subCompetencegroups &&
    selectedSubcompetencegroupId &&
    Array.isArray(subCompetencegroups) &&
    subCompetencegroups.find(
      competencegroup => competencegroup.id === selectedSubcompetencegroupId
    ).children;

  const selectedSubSubcompetencegroup =
    subSubCompetencegroups &&
    (selectedSubSubcompetencegroupId &&
      subSubCompetencegroups.find(
        competencegroup =>
          competencegroup.id === selectedSubSubcompetencegroupId
      ));

  return (
    <div
      className={classNames([
        'courses-menu',
        { 'courses-menu--mobile': isMobile },
      ])}>
      {!isMobile && (
        <div className="courses-menu__subcategory-wrapper">
          <CoursesMenuChipses
            competencegroups={competencegroups}
            handleCompetenceChipsClick={handleCompetenceChipsClick}
            selectedCompetencegroupId={selectedCompetencegroupId}
            selectedSubcompetencegroupId={selectedSubcompetencegroupId}
          />
          {subSubCompetencegroups && !!subSubCompetencegroups.length && (
            <>
              <div className="courses-menu__sorting-wrapper courses-menu__chipses-next">
                <div
                  className={classNames('sorting-active', {
                    active: subSubOpen,
                  })}
                  onClick={handleSubSubButtonClick}
                  onKeyPress={handleSubSubButtonClick}
                  onBlur={() => setSubSubOpen(false)}
                  role="button"
                  tabIndex="0">
                  {(selectedSubSubcompetencegroup &&
                    selectedSubSubcompetencegroup.title) ||
                    'Alle roller/kurs'}
                  <FontAwesomeIcon
                    icon={subSubOpen ? faChevronUp : faChevronDown}
                  />
                  {subSubOpen && (
                    <ul className="sorting-options">
                      {subSubCompetencegroups.map(({ id, title }, i) => (
                        <li
                          key={i}
                          className={`courses-menu__sorting-wrapper__button ${
                            selectedSubSubcompetencegroupId === id
                              ? 'active'
                              : 'inactive'
                          }`}>
                          <div
                            role="button"
                            tabIndex="0"
                            onKeyPress={() => {
                              coursesFiltersSetSubSubcompetencegroup({
                                subSubcompetencegroupId: id,
                              });
                              setSubSubOpen(false);
                            }}
                            onMouseDown={() => {
                              coursesFiltersSetSubSubcompetencegroup({
                                subSubcompetencegroupId: id,
                              });
                              setSubSubOpen(false);
                            }}>
                            {title}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <div className="courses-menu__buttons-wrapper">
        <div className="courses-menu__sorting-wrapper">
          <div
            className={classNames('sorting-active', {
              active: filtersOpen,
            })}
            onClick={handleSortingClick}
            onKeyPress={handleSortingClick}
            onBlur={() => setFiltersOpen(false)}
            role="button"
            tabIndex="0">
            {sorting[1]}
            <span className="courses-menu__toggle-sort-type-icon">
              <FontAwesomeIcon
                icon={filtersOpen ? faChevronUp : faChevronDown}
              />
            </span>
            {filtersOpen && (
              <ul className="sorting-options">
                {sorting_options.map((sort, i) => (
                  <li
                    key={i}
                    className={`courses-menu__sorting-wrapper__button courses-menu__sorting-wrapper__button-mobile ${
                      sorting[0] === sort[0] ? 'active' : 'inactive'
                    }`}>
                    <a
                      role="button"
                      tabIndex="0"
                      className={classNames(
                        'courses-menu__sorting-wrapper__sort-method-link',
                        i === sorting_options.length - 1
                          ? 'courses-menu__sorting-wrapper__sort-method-link-last'
                          : ''
                      )}
                      onKeyPress={() => {
                        handleSorting(sort);
                        setFiltersOpen(false);
                      }}
                      onMouseDown={() => {
                        handleSorting(sort);
                        setFiltersOpen(false);
                      }}
                      to={{ search: `sort=${sort[0]}` }}>
                      {sort[1]}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CoursesMenu.propTypes = {
  competencegroups: fetchedDataWrapperShape(
    PropTypes.arrayOf(PropTypes.shape({}))
  ).isRequired,
  selectedCompetencegroupId: PropTypes.number,
  selectedSubcompetencegroupId: PropTypes.object.isRequired,
  selectedSubSubcompetencegroupId: PropTypes.object.isRequired,
  coursesFiltersSetSubcompetencegroup: PropTypes.func.isRequired,
  coursesFiltersSetSubSubcompetencegroup: PropTypes.func.isRequired,
  coursesSetSorting: PropTypes.func.isRequired,
  sorting: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

CoursesMenu.defaultProps = {
  selectedCompetencegroupId: null,
};

const mapStateToProps = state => ({
  competencegroups: getCompetencegroups(state),
  sorting: getCoursesSorting(state),
  selectedCompetencegroupId: getSelectedCompetencegroupId(state),
  selectedSubcompetencegroupId: getSelectedSubcompetencegroupId(state),
  selectedSubSubcompetencegroupId: getSelectedSubSubcompetencegroupId(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      coursesFiltersSetSubcompetencegroup,
      coursesFiltersSetSubSubcompetencegroup,
      coursesSetSorting,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoursesMenu);
