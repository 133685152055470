import { useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import avatarFeatures, {
  getFeatureSourceByIndex,
} from '@components/person-avatar/avatar-features';
import { ReImg } from 'reimg';
import highscoreStar from '../../assets/images/highscore-star.png';
import extraBg from '../../assets/images/camp-extra-bg.png';
import topShopsTitle from '../../assets/images/title-topp-butikker.png';
import topEmployeesTitle from '../../assets/images/title-topp-fjellforere.png';
import campExtraLogo from '../../assets/images/camp-extra-logo.png';

import storeLevel1 from '../../assets/images/store-level-1.png';
import storeLevel2 from '../../assets/images/store-level-2.png';
import storeLevel3 from '../../assets/images/store-level-3.png';
import storeLevel4 from '../../assets/images/store-level-4.png';

const getStoreIcon = progress => {
  if (progress >= 90) {
    return storeLevel4;
  }
  if (progress >= 70) {
    return storeLevel3;
  }
  if (progress >= 40) {
    return storeLevel2;
  }
  return storeLevel1;
};

const HighscoreListCanvas = ({ width, height, data, orgs, onRender }) => {
  const ref = useRef();
  const [completed, setCompleted] = useState(false);
  const [imageCount, setImageCount] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  const renderCanvas = useCallback(() => {
    const loadImg = (src, callback) => {
      const img = new Image();
      setImageCount(i => i + 1);
      img.onload = () => {
        callback(img);
        setImagesLoaded(i => i + 1);
      };
      img.onerror = e => {
        callback(img, e);
        setImagesLoaded(i => i + 1);
      };
      img.src = src;

      return img;
    };

    // const loadImgMultiple = (sources, callback) => {
    //   let count = 0;

    //   const completed = [];
    //   const onComplete = () => {
    //     if (count >= sources.length) {
    //       callback(completed);
    //     }
    //   };

    //   return sources.map(src => {
    //     const img = new Image();
    //     setImageCount(i => i + 1);
    //     img.onload = () => {
    //       count += 1;
    //       completed.push(img);
    //       onComplete();
    //       setImagesLoaded(i => i + 1);
    //     };
    //     img.onerror = e => {
    //       count += 1;
    //       completed.push(img);
    //       onComplete();
    //       setImagesLoaded(i => i + 1);
    //     };
    //     img.src = src;

    //     return img;
    //   });
    // };

    const drawImg = (ctx, src, x, y, w, h) => {
      loadImg(src, img => {
        const ratio = Math.min(w / img.width, h / img.height);
        ctx.drawImage(
          img,
          x + (w - img.width * ratio) / 2,
          y + (h - img.height * ratio) / 2,
          img.width * ratio,
          img.height * ratio
        );
      });

      return ctx;
    };

    const roundRect = (ctx, x, y, w, h, radius) => {
      let r = radius;
      if (w < 2 * r) {
        r = w / 2;
      }
      if (h < 2 * r) {
        r = h / 2;
      }
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x + r, y);
      ctx.arcTo(x + w, y, x + w, y + h, r);
      ctx.arcTo(x + w, y + h, x, y + h, r);
      ctx.arcTo(x, y + h, x, y, r);
      ctx.arcTo(x, y, x + w, y, r);
      ctx.closePath();
      ctx.restore();
      return ctx;
    };

    const drawAvatar = (ctx, data, x, y, w, h) => {
      const layers = [
        getFeatureSourceByIndex({
          data,
          type: avatarFeatures.head,
        }),
        getFeatureSourceByIndex({
          data,
          type: avatarFeatures.body,
        }),
        getFeatureSourceByIndex({
          data,
          type: avatarFeatures.eyes,
        }),
        getFeatureSourceByIndex({
          data,
          type: avatarFeatures.mouth,
        }),
        getFeatureSourceByIndex({
          data,
          type: avatarFeatures.beard,
        }),
        getFeatureSourceByIndex({
          data,
          type: avatarFeatures.eyeWear,
        }),
        getFeatureSourceByIndex({
          data,
          type: avatarFeatures.hair,
        }),
        getFeatureSourceByIndex({
          data,
          type: avatarFeatures.hat,
        }),
      ];

      const avatarLayer = index => {
        if (index >= layers.length) {
          setImagesLoaded(i => i + 1);
          return;
        }

        const src = layers[index];

        if (src) {
          loadImg(src, img => {
            ctx.save();
            roundRect(ctx, x - w, y - h, w * 2, h * 2, 6);
            ctx.clip();
            ctx.drawImage(img, x, y, w, h);
            ctx.restore();
            avatarLayer(index + 1);
          });
        } else {
          avatarLayer(index + 1);
        }
      };

      setImageCount(i => i + 1);
      avatarLayer(0);

      return ctx;
    };

    if (!data) {
      return;
    }

    const canvas = document.createElement('canvas');
    ref.current = canvas;

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    ctx.width = width;
    ctx.height = height;

    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    ctx.fillStyle = '#DE243F';
    ctx.fillRect(0, 0, width, height);

    loadImg(extraBg, img => {
      ctx.save();
      ctx.globalAlpha = 0.64;
      const ratio = Math.max(width / img.width, height / img.height);
      ctx.drawImage(img, 0, 0, img.width * ratio, img.height * ratio);
      ctx.restore();

      const paddingX = 160;
      const paddingTop = 260;
      const marginBottom = 30;

      const elementWidth = width - paddingX * 2;
      const titleOverflow = 40;
      const elementHeight = 120;
      const avatarSize = elementHeight * 1.3;

      drawImg(
        ctx,
        (orgs && topShopsTitle) || topEmployeesTitle,
        paddingX - titleOverflow,
        60,
        elementWidth + titleOverflow * 2,
        120
      );

      data.map(
        (
          {
            fullname,
            name,
            points,
            completion,
            butikk_name,
            avatar: avatarData,
          },
          key
        ) => {
          const x = paddingX;
          const y = paddingTop + (elementHeight + marginBottom) * key;

          roundRect(ctx, x, y, elementWidth, elementHeight, 6);
          ctx.fillStyle = '#fff';
          ctx.fill();

          ctx.beginPath();
          ctx.arc(x + 50, y + elementHeight / 2, 25, 0, 2 * Math.PI, false);
          ctx.fillStyle = '#FFD200';
          ctx.fill();
          ctx.closePath();

          ctx.font = 'normal 24px sans-serif';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#E31C3A';
          ctx.fillText(key + 1, x + 50, y + elementHeight / 2 + 2);

          ctx.font = 'normal bold 26px sans-serif';
          ctx.textAlign = 'left';
          ctx.textBaseline = 'top';
          ctx.fillStyle = '#000';
          ctx.fillText(fullname || name, x + 110, y + 30);

          if (orgs) {
            ctx.font = 'normal bold 26px sans-serif';
            ctx.textAlign = 'left';
            ctx.textBaseline = 'top';
            ctx.fillStyle = '#E21A39';
            ctx.fillText(`Gjennomføringsgrad ${completion}%`, x + 110, y + 68);
          } else {
            ctx.font = 'normal 26px sans-serif';
            ctx.textAlign = 'left';
            ctx.textBaseline = 'top';
            ctx.fillStyle = '#AAAAAA';
            ctx.fillText(butikk_name, x + 110, y + 68);
            ctx.font = 'normal bold 26px sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'top';
            ctx.fillStyle = '#AAAAAA';
            ctx.fillText(points, x + elementWidth - avatarSize - 20, y + 66);
            drawImg(
              ctx,
              highscoreStar,
              x + elementWidth - avatarSize - 36,
              y + 24,
              32,
              32
            );
          }

          if (orgs) {
            drawImg(
              ctx,
              getStoreIcon(completion),
              x + elementWidth - avatarSize * 0.8,
              y - avatarSize * 0.73 + elementHeight,
              avatarSize * 0.7,
              avatarSize * 0.7
            );
          } else {
            let avatar;
            try {
              avatar = JSON.parse(avatarData);
            } catch {
              avatar = null;
            }

            if (avatar) {
              drawAvatar(
                ctx,
                avatar,
                x + elementWidth - avatarSize * 0.85,
                y - avatarSize + elementHeight,
                avatarSize,
                avatarSize
              );
            }
          }

          drawImg(ctx, campExtraLogo, 40, height - 140, 120, 120);

          return key;
        }
      );

      setCompleted(true);
    });
  }, [width, height, data]);

  useEffect(() => {
    setCompleted(false);
    setImageCount(0);
    setImagesLoaded(0);
    renderCanvas();
  }, [renderCanvas]);

  useEffect(() => {
    if (completed && imagesLoaded === imageCount) {
      setCompleted(false);
      setTimeout(() => {
        ReImg.fromCanvas(ref.current).downloadPng();
        onRender();
      }, 400);
      onRender();
    }
  }, [completed, imageCount, imagesLoaded, ref.current]);

  return null;
};

HighscoreListCanvas.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  orgs: PropTypes.bool,
  onRender: PropTypes.func.isRequired,
};

HighscoreListCanvas.defaultProps = {
  orgs: false,
};

export default HighscoreListCanvas;
