import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const MainText = styled.div`
  flex: 0 1 auto;
  white-space: nowrap;
  color: #ef591b;
  font-weight: bold;

  ${({ small }) =>
    small
      ? css`
          font-size: 1.8em;
          color: #6a6868;
        `
      : css`
          font-size: 2.5em;
          color: #ef591b;
        `}
`;

const SubText = styled.div`
  flex: 0 1 auto;
  white-space: nowrap;
  color: #b6b6b6;
  font-size: 1em;
`;

const Column = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
`;

const CardBackground = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
  box-shadow: 1px 1px 8px rgba(99, 99, 99, 0.4);
  line-height: 1.1;

  ${({ courseCard }) =>
    courseCard
      ? css`
          padding: 20px 14px;
          background-color: #ef591b;
          margin: 10px 0;

          ${Column} {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }

          ${MainText} {
            color: #fff;
            font-size: 1.3em;
            margin-right: 20px;
          }
          ${SubText} {
            color: #fff;
            font-size: 0.9em;
          }
        `
      : css`
          padding: 20px;
          background-color: #fff;
          margin: 14px 0;
        `}
`;

const ProgressCard = forwardRef(
  ({ mainText, subText, small, columns, courseCard }, ref) => {
    return (
      <CardBackground ref={ref} courseCard={courseCard}>
        {(mainText || subText) && (
          <Column>
            <MainText small={small}>{mainText}</MainText>
            <SubText>{subText}</SubText>
          </Column>
        )}
        {columns &&
          columns.map(({ mainText, subText, small }) => (
            <Column>
              <MainText small={small}>{mainText}</MainText>
              <SubText>{subText}</SubText>
            </Column>
          ))}
      </CardBackground>
    );
  }
);

ProgressCard.propTypes = {
  mainText: PropTypes.string,
  subText: PropTypes.string,
  small: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      mainText: PropTypes.string,
      subText: PropTypes.string,
      small: PropTypes.bool,
    })
  ),
  courseCard: PropTypes.bool,
};

ProgressCard.defaultProps = {
  mainText: '',
  subText: '',
  small: false,
  columns: null,
  courseCard: false,
};

export default ProgressCard;
