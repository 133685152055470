export const employees = {
  dashboard: '/dashboard/:orgId',
  employees: '/employees/:orgId',
  addEmployee: '/employees/:orgId/add',
  employeePreview: '/employees/:orgId/:userName',
  employeeReport: '/employees/:orgId/:userName/fullreport',
  reportPreview: '/dashboard/report/:reportId',
  campExtra: '/dashboard/camp-extra',
  employeeEdit: '/employees/:orgId/:userName/edit',
  employeeMessage: '/employees/:orgId/:userName/message',
  report: '/dashboard/competence-report/:reportId',
  reportNew: '/dashboard/competence-report',
  reportSearch: '/dashboard/competence-report/search',
  reportBrandschool: '/dashboard/competence-report/totalt',
};

export const courseCatalog = {
  main: {
    index: 0,
    path: '/course-catalog',
    active: '/course-catalog',
    breadcrumb: 'Kurskatalog',
  },
  coursePreview: {
    index: 1,
    path: '/course-catalog/:cid',
    active: '/course-catalog/',
    breadcrumb: 'Kurs',
  },
};

export const myEducation = {
  main: {
    index: 0,
    path: '/my-education',
    active: '/my-education',
    breadcrumb: 'Min side',
  },
  profileView: {
    index: 1,
    path: '/my-education/profile',
    active: '/my-education/profile',
    breadcrumb: 'Rediger profil',
  },
  courseView: {
    index: 3,
    path: '/my-education/course/:courseId',
    active: '/my-education/course',
    breadcrumb: 'Kurs',
  },
  cvView: {
    index: 4,
    path: '/my-education/cv/:personId',
    active: '/my-education/cv',
    breadcrumb: 'Min kompetanse',
  },
  messagesView: {
    index: 7,
    path: '/my-education/messages/:personId',
    active: '/my-education/messages',
    breadcrumb: 'Mine meldinger',
  },
  roleView: {
    index: 2,
    path: '/my-education/role/:roleId',
    active: '/my-education/role',
    breadcrumb: 'Rolle',
  },
  roleCourseView: {
    index: 5,
    path: '/my-education/role/:roleId/:courseId',
    active: '/my-education/role/:roleId/',
    breadcrumb: 'Kurs',
  },
};

export default {
  courseCatalog,
  myEducation,
};
