import './course-card.scss';
import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { Link } from 'react-router-dom';
import AspectRatio from '@components/common/aspect-ratio';
import CourseDuration from '@components/course/course-duration';
import DEFAULT_BG from '../../../../../../assets/images/default_coop.jpg';

const createSubtitleContent = content => ({
  __html: content,
});

export const CourseCardLoader = () => (
  <div className="course-card course-card-loading">
    <div className="course-card__cover">
      <AspectRatio ratio={1}>
        <div
          className="course-card__cover--image"
          style={{
            backgroundColor: '#f3f3f3',
          }}
        />
      </AspectRatio>
    </div>
    <div className="course-card__content">
      <ContentLoader
        height="150"
        width="250"
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb">
        <rect x="4" y="20" rx="4" ry="4" width="225" height="25" />
        <rect x="4" y="65" rx="4" ry="4" width="180" height="18" />
      </ContentLoader>
    </div>
  </div>
);

const CourseCard = ({
  cover,
  title,
  competence_type,
  description: subtitleHTML,
  href,
  duration,
  type,
  isMobile,
}) => {
  return (
    <Link className="course-card" to={href}>
      <div className="course-card__cover">
        <AspectRatio
          ratio={1}
          style={{
            position: 'relative',
          }}>
          <div
            className="course-card__cover--image"
            style={{
              backgroundImage: `url(${(cover && cover.url) || DEFAULT_BG})`,
            }}
          />
          <div className="course-card__cover--icon">{competence_type}</div>
        </AspectRatio>
      </div>
      <div className="course-card__content">
        {!isMobile && (
          <>
            {(duration !== null || type !== null) && (
              <CourseDuration
                className="course-card__content--time"
                duration={duration}
                metric={type}
                iconType="stopwatch"
              />
            )}
            <div className="course-card__content--description">
              <div className="course-card__content--title">{title}</div>
              {subtitleHTML && (
                <div
                  className="course-card__content--subtitle"
                  dangerouslySetInnerHTML={createSubtitleContent(subtitleHTML)}
                />
              )}
            </div>
          </>
        )}
        {isMobile && (
          <>
            <div className="course-card__content--title">{title}</div>
            {(duration !== null || type !== null) && (
              <CourseDuration
                className="course-card__content--time"
                duration={duration}
                metric={type}
                iconType="stopwatch"
              />
            )}
          </>
        )}
      </div>
      {isMobile && subtitleHTML && (
        <div
          className="course-card__content--subtitle"
          dangerouslySetInnerHTML={createSubtitleContent(subtitleHTML)}
        />
      )}
    </Link>
  );
};

CourseCard.propTypes = {
  cover: PropTypes.shape({
    url: PropTypes.string,
  }),
  competence_type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string.isRequired,
  duration: PropTypes.number,
  isMobile: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

CourseCard.defaultProps = {
  cover: null,
  title: '',
  competence_type: '',
  description: '',
  duration: null,
  type: null,
};

export default CourseCard;
