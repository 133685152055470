import { eventChannel, END } from 'redux-saga';
import {
  all,
  call,
  put,
  take,
  takeLatest,
  takeEvery,
  select,
  throttle,
} from 'redux-saga/effects';
import axios from 'axios';
import { backendUrl } from '@config';

import { stringifyUrlParams } from '@utils/requests.utils';

import { ROUTER_COURSE_CATALOG_COURSE_PREVIEW_DID_MOUNT } from '@actions/router.actions';
import { notificationsAdd } from '@actions/notifications.actions';
import * as alertActions from '@actions/alert.actions';

import retry from '@utils/sagas.utils';

import * as coursesActions from '@actions/courses.actions';
import {
  profileFetchPersonEvents,
  profileUpdateOneCompetences,
} from '@actions/profile.actions';

import { getEmployeesEvents } from '@selectors/employees.selectors';

import { employeesFetchEvents } from '@actions/employees.actions';

import {
  getCompetencesSearchTerm,
  getSelectedCompetencegroupId,
  getSelectedSubcompetencegroupId,
  getSelectedSubSubcompetencegroupId,
  getSelectedCompetencetypes,
} from '@selectors/courses.selectors';

import {
  getProfile,
  isManager,
  getProfileUserName,
} from '@selectors/profile.selectors';

function* getCompetences() {
  const fields = [
    'short_description',
    'files',
    'title',
    'modified',
    'course_type',
    'competence_type_id',
    'durations',
    'has_parents',
    'competence_type',
  ].join(',');

  const searchTerm = yield select(getCompetencesSearchTerm) || null;

  const selectedCompetencegroupId = [
    yield select(getSelectedCompetencegroupId),
  ];

  const selectedSubcompetencegroupId = yield select(
    getSelectedSubcompetencegroupId
  );

  const selectedSubSubcompetencegroupId = yield select(
    getSelectedSubSubcompetencegroupId
  );

  const selectedCompetencetypes = [
    ...(yield select(getSelectedCompetencetypes)),
  ];

  const types =
    (selectedCompetencetypes.length && selectedCompetencetypes.join(',')) ||
    'complex,ecourse,equivalents,course';

  let params = encodeURI(
    stringifyUrlParams({
      fields,
      view: 'full',
      'competence_group_ids[]':
        selectedSubSubcompetencegroupId ||
        selectedSubcompetencegroupId ||
        selectedCompetencegroupId,
      'types[]': types,
    })
  );

  if (params) {
    params = `?${params}`;
  }

  yield put(coursesActions.coursesGetCompetencesRequest());
  try {
    let competences;
    const limit = 100;
    if (searchTerm) {
      competences = yield retry(() =>
        axios
          .request({
            method: 'GET',
            params: {
              term: searchTerm,
              course_types: types,
              not_course_groups: '107,',
            },
            url: `${backendUrl}/plugin/search_courses`,
            withCredentials: true,
          })
          .then(response => response.data.courses)
      );
    } else {
      competences = yield retry(() =>
        axios
          .request({
            method: 'GET',
            params: {
              children: '1',
              limit: limit + 1,
            },
            url: `${backendUrl}/api/competences/${params}`,
            withCredentials: true,
          })
          .then(response => response.data.competences)
      );
    }

    let filter_out = null;
    if (selectedCompetencegroupId && selectedCompetencegroupId[0] !== 56) {
      filter_out = competences.filter(c => !c.has_parents);
    } else {
      filter_out = competences;
    }

    let hasMore = false;
    if (filter_out.length >= limit) {
      hasMore = true;
      filter_out = filter_out.slice(0, limit - 1);
    }

    yield put(
      coursesActions.coursesGetCompetencesSuccess({
        competences: filter_out,
        hasMore,
      })
    );
  } catch (error) {
    yield put(coursesActions.coursesGetCompetencesFailure({ error }));
  }
}

export function getCourseEventsAPI(payload) {
  const fields = [
    'short_description',
    'title',
    'startdate',
    'competence_id',
    'enddate',
    'description',
    'location',
  ].join(',');

  let params = '';
  if (payload.userName) {
    params = encodeURI(
      stringifyUrlParams({
        fields,
        view: 'full',
        user_name: payload.userName,
      })
    );
  } else {
    params = encodeURI(
      stringifyUrlParams({
        fields,
        view: 'full',
      })
    );
  }

  if (params) {
    params = `?${params}`;
  }

  let gourl = `${backendUrl}/api/events${params}`;
  if (payload.userName) {
    gourl = `${backendUrl}/api/personevents${params}`;
  }

  return axios
    .request({
      method: 'get',
      url: gourl,
      params: {
        limit: 100,
      },
      withCredentials: true,
    })
    .then(function(response) {
      return response.data;
    });
}

function* getCourseEvents() {
  yield put(coursesActions.coursesGetCourseEventsRequest());
  try {
    const events = yield call(getCourseEventsAPI, {});
    yield put(
      coursesActions.coursesGetCourseEventsSuccess({
        courseEvents: events.events,
      })
    );
  } catch (error) {
    yield put(coursesActions.coursesGetCourseEventsFailure({ error }));
  }
}

function* getCompetencegroups() {
  yield put(coursesActions.coursesGetCompetencegroupsRequest());
  try {
    let competencegroups = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/competencegroups`,
          params: {
            fields: 'title,children(title)',
          },
          withCredentials: true,
        })
        .then(response => response.data.competencegroups)
    );
    competencegroups =
      competencegroups && competencegroups.filter(({ id }) => id !== 107);
    yield put(
      coursesActions.coursesGetCompetencegroupsSuccess({ competencegroups })
    );
  } catch (error) {
    yield put(coursesActions.coursesGetCompetencegroupsFailure({ error }));
  }
}

function* getCompetencetypes() {
  yield put(coursesActions.coursesGetCompetencetypesRequest());
  try {
    const competencetypes = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/competencetypes/`,
          withCredentials: true,
        })
        .then(response => response.data.competencetypes)
    );
    yield put(
      coursesActions.coursesGetCompetencetypesSuccess({ competencetypes })
    );
  } catch (error) {
    yield put(coursesActions.coursesGetCompetencetypesFailure({ error }));
  }
}

function* fetchMyCoursesViewData() {
  yield put(coursesActions.coursesInitializeMyCoursesViewRequest());
  try {
    yield all([
      yield call(getCompetencegroups),
      yield call(getCompetencetypes),
    ]);
    yield call(getCompetences);
    yield put(coursesActions.coursesInitializeMyCoursesViewSuccess());
  } catch {
    yield put(coursesActions.coursesInitializeMyCoursesViewFailure());
  }
}

function* fetchCompetenceDetails(action) {
  const { cid } = action.payload;
  yield put(coursesActions.coursesEndCourse());

  const fields = [
    'description',
    'files',
    'title',
    'course_type',
    'competence_type_id',
    'competence_type',
    'durations',
    'children(competence_type,description,competence_type_id,title,competence_id,files)',
  ].join(',');

  const fields_events = ['title', 'location'].join(',');

  const params = encodeURI(
    stringifyUrlParams({
      fields,
      view: 'full',
    })
  );

  const params_events = encodeURI(
    stringifyUrlParams({
      fields_events,
      view: 'full',
    })
  );

  yield put(coursesActions.coursesFetchCompetenceDetailsGetRequest());
  try {
    const {
      data: {
        competences: [competenceDetails],
      },
    } = yield retry(() =>
      axios.request({
        method: 'GET',
        params: {
          children: '1',
        },
        url: `${backendUrl}/api/competences/${cid}?${params}`,
        withCredentials: true,
      })
    );

    if (competenceDetails.competence_type_id) {
      /*
        this is classroom course, the events for it.
       */
      const {
        data: { events: eventsDetails },
      } = yield retry(() =>
        axios.request({
          method: 'GET',
          params: {
            children: '1',
          },
          url: `${backendUrl}/api/competences/${cid}/events?${params_events}`,
          withCredentials: true,
        })
      );
      competenceDetails.events = eventsDetails;
      /*
      # if manager, and course details

       */
      let userName = yield select(getProfileUserName);
      while (userName === null) {
        yield take();
        userName = yield select(getProfileUserName);
      }
      const isUserManager = yield select(isManager);
      console.log('isUserManager');
      console.log(isUserManager);
      if (isUserManager) {
        const userEvents = yield select(getEmployeesEvents);
        if (!userEvents) {
          console.log('FETCH THE EMPLYEES EVENTs');
          yield put(employeesFetchEvents({}));
        }
      }
    }

    competenceDetails.competence_type = {
      competence_type: competenceDetails.competence_type,
      competence_type_id: competenceDetails.competence_type_id,
    };

    if (competenceDetails.children) {
      for (let i = 0; i < competenceDetails.children.length; i += 1) {
        competenceDetails.children[i].competence_type = {
          competence_type: competenceDetails.children[i].competence_type,
          competence_type_id: competenceDetails.children[i].competence_type_id,
        };
      }
    }

    yield put(
      coursesActions.coursesFetchCompetenceDetailsGetSuccess({
        competenceDetails,
      })
    );
  } catch (error) {
    yield put(
      coursesActions.coursesFetchCompetenceDetailsGetFailure({ error })
    );
  }
}

function* courseSignOn(action) {
  const { courseEventId, employees } = action.payload;

  const { data: person } = yield select(getProfile);
  const signedEmployees = employees || [person];

  yield put(coursesActions.courseSignOnRequest({ ceid: courseEventId }));
  try {
    const results = {
      correct: [],
      errors: [],
    };
    console.log('sign me up.....');
    console.log(signedEmployees);
    yield all(
      signedEmployees.map(({ fullname, user_name }) => {
        const user = fullname && fullname.trim() !== '' ? fullname : user_name;
        return call(() =>
          axios
            .request({
              method: 'POST',
              url: `${backendUrl}/api/personevents/${courseEventId}`,
              params: {
                user_name,
              },
              withCredentials: true,
            })
            .then(response => {
              results.correct.push({
                user,
                response,
              });
              return response;
            })
            .catch(error => {
              results.errors.push({
                user,
                error,
              });
              return error;
            })
        );
      })
    );

    if (!employees) {
      let notification;
      if (results.correct && results.correct.length > 0) {
        notification = {
          text: results.correct[0].response.data.message,
          color: 'green',
        };
      } else if (results.errors && results.errors.length > 0) {
        notification = {
          title: 'Feil',
          text: results.errors[0].error.message,
          color: 'red',
        };
      }
      yield put(profileFetchPersonEvents({}));
      yield put(notificationsAdd({ notification }));
    } else {
      yield put(employeesFetchEvents({}));
    }

    yield put(coursesActions.courseSignOnResults({ results }));
    yield put(coursesActions.courseSignOnSuccess());
  } catch (error) {
    yield put(coursesActions.courseSignOnFailure({ error }));
  }
}

function* coursePreviewLoaded(action) {
  try {
    yield call(fetchCompetenceDetails, action);
  } catch (e) {
    console.error(e);
  }
  yield put(alertActions.actionClear());
}

function* onCourseStart(action) {
  try {
    const { cid, type } = action.payload;

    if (type === 10) {
      const { valid, lmsstarturls } = yield call(() =>
        axios
          .request({
            method: 'get',
            url: `${backendUrl}/api/lmsstarturls?competence_ids=${cid}`,
            withCredentials: true,
          })
          .then(response => response.data)
      );

      const [{ urls }] = lmsstarturls;

      if (valid === true && urls[0]) {
        // CLEAR THE INFO BOX.
        const open_url = `${backendUrl}${urls[0].id}`;
        const win = window.open(open_url, '_blank');
        yield put(
          coursesActions.coursesLmsRunning({ url: open_url, opened: !!win })
        );
      } else {
        yield put(coursesActions.coursesLmsError());
      }
    } else {
      const iframeMessageChannel = yield call(() =>
        eventChannel(emmiter => {
          window.addEventListener('message', ({ data }) => {
            if (
              data === 'IFRAME_COURSE_FINISHED' ||
              data === 'TAB_COURSE_FINISHED'
            ) {
              emmiter();
              emmiter(END);
            }
          });
          return () => {
            window.removeEventListener('message');
          };
        })
      );
      yield take(iframeMessageChannel);
      yield put(coursesActions.coursesCourseFinished({ cid }));
    }
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesCourseFailure({ error }));
  }
}

function* onCourseSign(action) {
  const { courseId, password } = action.payload;
  const bodyFormData = new FormData();
  bodyFormData.set('formIndex', '0');
  bodyFormData.set('password', password);
  let status;
  try {
    const data = yield call(() =>
      axios
        .request({
          method: 'POST',
          url: `${backendUrl}/courses/sign_digitally/${courseId}`,
          data: bodyFormData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } },
          withCredentials: true,
        })
        .then(response => response.data)
    );
    ({ status } = data);
    if (data.statuscode === -1) {
      yield put(
        coursesActions.coursesSignCourseSuccess({
          status: data.status,
          courseId,
        })
      );
      yield put(
        notificationsAdd({
          notification: {
            text: data.status,
            color: 'green',
          },
        })
      );
      yield put(profileUpdateOneCompetences());
      yield put(coursesActions.coursesBeginSignature(null));
    } else {
      if (status) {
        yield put(
          notificationsAdd({
            notification: {
              text: status,
              color: 'red',
            },
          })
        );
      }
      coursesActions.coursesSignCourseError();
    }
  } catch (error) {
    if (status) {
      yield put(
        notificationsAdd({
          notification: {
            text: status,
            color: 'red',
          },
        })
      );
    }
    coursesActions.coursesSignCourseError({ error });
  }
}

export default [
  takeLatest(coursesActions.COURSE_SIGNON, courseSignOn),
  takeLatest(coursesActions.COURSES_GET_COMPETENCES, getCompetences),
  takeLatest(coursesActions.COURSES_GET_COMPETENCEGROUPS, getCompetencegroups),
  takeLatest(coursesActions.COURSES_GET_COMPETENCETYPES, getCompetencetypes),
  takeLatest(
    coursesActions.COURSES_INITIALIZE_MY_COURSES_VIEW,
    fetchMyCoursesViewData
  ),
  takeLatest(coursesActions.COURSES_FILTERS_SET_FILTERS, getCompetences),
  throttle(700, coursesActions.COURSES_SET_SEARCHTERM, getCompetences),
  takeLatest(
    coursesActions.COURSES_FILTERS_SET_COMPETENCEGROUP,
    getCompetences
  ),
  takeLatest(
    coursesActions.COURSES_FILTERS_SET_SUBCOMPETENCEGROUP,
    getCompetences
  ),
  takeLatest(
    coursesActions.COURSES_FILTERS_SET_SUB_SUBCOMPETENCEGROUP,
    getCompetences
  ),
  takeLatest(
    coursesActions.COURSES_FILTERS_TOGGLE_COMPETENCETYPE,
    getCompetences
  ),
  takeLatest(coursesActions.COURSES_GET_COURSEEVENTS, getCourseEvents),
  takeLatest(
    ROUTER_COURSE_CATALOG_COURSE_PREVIEW_DID_MOUNT,
    coursePreviewLoaded
  ),
  takeLatest(coursesActions.COURSES_START_COURSE, onCourseStart),
  takeEvery(coursesActions.COURSES_SIGN_COURSE, onCourseSign),
];
