import Cookies from 'js-cookie';
import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT_SUCCESS,
  AUTH_UNAUTHORIZED,
  AUTH_LOGIN_GOTO_ON,
  AUTH_LOGIN_NEW_PASSWORD_REQUEST,
  AUTH_LOGIN_NEW_PASSWORD_SUCCESS,
  AUTH_LOGIN_NEW_PASSWORD_FAILURE,
} from '@actions/auth.actions';
import { AUTH_LOGIN_SUCCESS_CHOICE } from '../actions/auth.actions';

const initialState = {
  authProfile: undefined,
  newPwd: undefined,
  secondLogin: false,
  secondData: undefined,
  sessionId: Cookies.get('tg-visit') || 'sd',
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
    case AUTH_LOGIN_FAILURE:
    case AUTH_LOGOUT_SUCCESS:
    case AUTH_UNAUTHORIZED:
      return {
        ...state,
        authProfile: undefined,
        sessionId: undefined,
      };
    case AUTH_LOGIN_GOTO_ON:
      return {
        ...state,
        secondLogin: false,
        secondData: undefined,
      };
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        authProfile: action.payload.authProfile,
        sessionId: action.payload.authProfile.session_id,
        secondLogin: false,
      };
    case AUTH_LOGIN_SUCCESS_CHOICE:
      return {
        ...state,
        authProfile: action.payload.authProfile,
        sessionId: action.payload.authProfile.session_id,
        secondLogin: true,
        secondData: { username: action.payload.username, password: action.payload.password },
      };
    case AUTH_LOGIN_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        newPwdStatus: -1,
        newPwd: null,
      };
    case AUTH_LOGIN_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        newPwdStatus: 2,
        newPwd: action.payload.message,
      };
    case AUTH_LOGIN_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        newPwdStatus: 1,
        newPwd: action.payload.message,
      };
    default:
      return state;
  }
};

export default auth;
