import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';

import { myEducation as myEducationRoutes } from '@routes/routes.manifest';

import Progress from '@components/progress/progress';
import ThemedLogo from '@components/themed-logo/themed-logo';

import './mobile-menu.scss';
import PersonAvatar from '../../person-avatar/person-avatar';

const RecursiveMenuLinks = ({ onMenuItemClick, menuLinks, child = false }) =>
  (menuLinks &&
    menuLinks.map(({ name, path, active, exact = false, children }, index) => (
      <>
        <NavLink
          className={classNames('mobile-menu__sidebar-link', {
            'mobile-menu__sidebar-link--active': active,
            'mobile-menu__sidebar-link-child': child,
          })}
          activeClassName="mobile-menu__sidebar-link--active"
          onClick={onMenuItemClick}
          exact={exact}
          to={path}
          strict={false}
          key={index}>
          {name}
        </NavLink>
        <RecursiveMenuLinks
          onMenuItemClick={onMenuItemClick}
          menuLinks={children}
          child
        />
      </>
    ))) ||
  null;

const MobileMenu = withRouter(
  ({
    menuLinks,
    isSidebarOpen,
    onLogoutClick,
    onToggleSidebarClick,
    onMenuItemClick,
    profile,
  }) => {
    return (
      <div className="mobile-menu">
        <div className="mobile-menu__header">
          <div className="mobile-menu__logo-wrapper">
            <ThemedLogo className="mobile-menu__logo" />
          </div>
          <div className="mobile-menu__toggle-sidebar-button-wrapper">
            <button
              className="mobile-menu__toggle-sidebar-button"
              onClick={onToggleSidebarClick}
              type="button">
              {isSidebarOpen ? (
                <i className="fas fa-times" />
              ) : (
                <i className="fas fa-bars" />
              )}
            </button>
          </div>
        </div>
        <div className="mobile-menu__bottom">
          {profile && (
            <div className="mobile-menu__profile">
              <div className="mobile-menu__avatar">
                <PersonAvatar
                  className="mobile-menu__avatar-image"
                  person={profile}
                  size="60px"
                  fontSize="28px"
                  link
                  to={myEducationRoutes.profileView.path}
                />
              </div>
              <Progress
                className="mobile-menu__progress"
                progress={profile.competencelevel}
              />
            </div>
          )}
        </div>
        <div
          className={classNames('mobile-menu__sidebar', {
            'mobile-menu__sidebar--opened': isSidebarOpen,
          })}>
          <div
            className={classNames('mobile-menu__sidebar-content', {
              'mobile-menu__sidebar-content--opened': isSidebarOpen,
            })}>
            {/* <div className="mobile-menu__search-wrapper">
          <div className="mobile-menu__search">
            <input
              className="mobile-menu__search-input"
              placeholder="Hva leter du etter?"
            />
            <div className="mobile-menu__search-icon">
              <i className="fas fa-search" />
            </div>
          </div>
        </div> */}
            <div className="mobile-menu__sidebar-links">
              <RecursiveMenuLinks
                onMenuItemClick={onMenuItemClick}
                menuLinks={menuLinks}
              />
            </div>
            <div className="mobile-menu__sidebar-buttons">
              <button
                className="mobile-menu__sidebar-button"
                onClick={onLogoutClick}
                type="button">
                <i className="fas fa-sign-out-alt" />
                Logg ut
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

MobileMenu.propTypes = {
  menuLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  onToggleSidebarClick: PropTypes.func.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  profile: PropTypes.shape({}),
};

MobileMenu.defaultProps = {
  profile: null,
};

export default MobileMenu;
