import { useEffect, useCallback } from 'react';

export const useOnClickOutside = (refs, callback) => {
  const listener = useCallback(
    event => {
      const forAny = fn =>
        Array.isArray(refs)
          ? refs.reduce(
              (bool, ref) =>
                !!(bool || (ref && ref.current && fn(ref.current))),
              false
            )
          : refs && refs.current && fn(refs.current);
      if (
        !forAny(current => {
          return current.contains(event.target);
        })
      ) {
        callback(event);
      }
    },
    [callback, refs]
  );

  useEffect(() => {
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [listener]);
};
