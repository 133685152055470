import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { routerMyEducationRoleViewDidMount } from '@actions/router.actions';
import { myEducation as myEducationRoutes } from '@routes/routes.manifest';

import '../styles/my-education-role-container.scss';
import { Link } from 'react-router-dom';
import { backendUrl } from '@config';
import { getRouteWithParams } from '@utils/routes.utils';
import Progress from '@components/progress/progress';
import { withRouter } from 'react-router';
import classnames from 'classnames';

import CompetenceCourse from '@components/competences/competence-course';
import CompetenceEcourse from '@components/competences/competence-ecourse';
import CompetenceEquivalents from '@components/competences/competence-equals';
import CompetenceComplex from '@components/competences/competence-complex';
import CompetenceSignature from '@components/competences/competence-signature';
import CompetenceChecklist from '@components/competences/competence-checklist';
import CompetenceChecklistItem from '@components/competences/competence-checklist-item';
import CompetenceVerification from '@components/competences/competence-verification';

import DEFAULT_BG from '../../../assets/images/default_coop.jpg';

/*
const createSubtitleContent = content => ({
  __html: content,
});
*/

class MyEducationRoleContainer extends Component {
  state = {
    isPassedContainerExpanded: false,
    isRequiredContainerExpanded: false,
  };

  componentDidMount() {
    const {
      routerMyEducationRoleViewDidMount,
      match: {
        params: { roleId },
      },
    } = this.props;

    routerMyEducationRoleViewDidMount(roleId);
  }

  componentWillUnmount() {
    console.log('UMOUNT IT. ');
  }

  render() {
    const { match, isSubFetching, role } = this.props;
    const {
      isPassedContainerExpanded,
      isRequiredContainerExpanded,
    } = this.state;

    let myPassedCompetences = [];
    let passed_length = 0;

    let showPassed = [];
    const myRole = role.data;
    let showComps = [];
    let showOblComps = [];
    let required_length = 0;
    let required_show_length = 0;
    let required_passed_length = 0;
    let progress = 0;
    if (role.data) {
      myPassedCompetences = role.data.requirements.filter(
        c => c.passed === 100
      );

      passed_length = myPassedCompetences.length;
      required_length = role.data.requirements.filter(
        c => c.requirement_type === 'Mandatory'
      ).length;
      required_passed_length = role.data.requirements.filter(
        c => c.requirement_type === 'Mandatory' && c.passed === 100
      ).length;

      if (required_passed_length !== 0) {
        progress = Math.round((required_passed_length / required_length) * 100);
      }

      if (myRole.id === -2) {
        showComps = role.data.requirements.filter(c => c.passed !== 100);
      } else {
        showComps = role.data.requirements.filter(
          c => c.requirement_type === 'Mandatory' && c.passed !== 100
        );
        showOblComps = role.data.requirements.filter(
          c => c.requirement_type === 'Optional' && c.passed !== 100
        );
      }

      showPassed = myPassedCompetences;
      if (!isPassedContainerExpanded) {
        showPassed = [...myPassedCompetences].splice(0, 3);
      }

      required_show_length = showComps.length;
      if (!isRequiredContainerExpanded && showComps.length > 5) {
        showComps = [...showComps].splice(0, 5);
      }

      showPassed.map(function(course) {
        console.log('map');
        console.log(course);
        return true;
      });
    }

    if (role.isFetching || !role.data) {
      return (
        <div className="my-education">
          <div className="loading">
            <div className="my-education__section">
              <div className="my-education__section__role-wrapper">
                <div className="my-education__section__role-header">
                  <div className="loading__title" />
                  <div className="loading__text" />
                </div>
              </div>
            </div>
            <div className="loading__content">
              <h3>...</h3>
              <div className="grid-x">
                <div className="cell small-5 loading__image" />
                <div className="cell small-4 loading__checklist">
                  <div className="loading__title" />
                  <div className="loading__text" />
                  <div className="loading__text loading__text--short" />
                  <div className="loading__text loading__text--short loading__text--small" />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="my-education">
          <div className="my-education__section">
            <div className="my-education__section__role-wrapper">
              <div className="my-education__section__role-header">
                <div className="grid-x">
                  <div className="cell small-12 medium-8">
                    <h1>{myRole.title}</h1>
                    {myRole.id !== -2 && (
                      <div>{required_length} oppgaver tilknyttet rollen</div>
                    )}
                  </div>
                  <div className="cell small-12 medium-4 center-vertical">
                    {myRole.id !== -2 && <Progress progress={progress} />}
                  </div>
                </div>
              </div>
              {showComps && showComps.length !== 0 && (
                <div className="my-education__section__role-required">
                  <h2>
                    Dine oppgaver relatatert til din rolle som {myRole.title}
                  </h2>
                  {showComps.map(course => (
                    <div key={course.id}>
                      <div
                        className="my-education__section__role-wrapper__card"
                        key={course.id}>
                        <div
                          key={`competence-list-sub-${course.competence_id}`}
                          className="grid-x">
                          {(course.competence.competence_type
                            .competence_type === 'ecourse' && (
                            <CompetenceEcourse
                              type="ecourse"
                              size="big"
                              isFetching={isSubFetching}
                              course={course.competence}
                              roleId={myRole.id}
                              root="show-role"
                            />
                          )) ||
                            (course.competence.competence_type
                              .competence_type === 'course' && (
                              <CompetenceCourse
                                type="course"
                                size="big"
                                course={course.competence}
                                roleId={myRole.id}
                                root="show-role"
                              />
                            )) ||
                            (course.competence.competence_type
                              .competence_type === 'digital_signature' && (
                              <CompetenceSignature
                                type="signature"
                                size="big"
                                course={course.competence}
                                roleId={myRole.id}
                                root="show-role"
                              />
                            )) ||
                            (course.competence.competence_type
                              .competence_type === 'complex' && (
                              <CompetenceComplex
                                isFetching={isSubFetching}
                                type="complex"
                                size="big"
                                course={course.competence}
                                roleId={myRole.id}
                                root="show-role"
                              />
                            )) ||
                            (course.competence.competence_type
                              .competence_type === 'equivalents' && (
                              <CompetenceEquivalents
                                isFetching={isSubFetching}
                                type="equivalents"
                                size="big"
                                course={course.competence}
                                roleId={myRole.id}
                                root="show-role"
                              />
                            )) ||
                            (course.competence.competence_type
                              .competence_type === 'verification' && (
                              <CompetenceVerification
                                type="verification"
                                size="big"
                                course={course.competence}
                                roleId={myRole.id}
                                root="show-role"
                              />
                            )) ||
                            (course.competence.competence_type
                              .competence_type === 'checklist' && (
                              <CompetenceChecklist
                                isFetching={isSubFetching}
                                type="checklist"
                                size="big"
                                root="show-role"
                                course={course.competence}
                                roleId={myRole.id}
                              />
                            )) ||
                            (course.competence.competence_type
                              .competence_type === 'checklist_item' && (
                              <CompetenceChecklistItem
                                isFetching={isSubFetching}
                                type="checklist"
                                size="big"
                                root="show-role"
                                course={course}
                                roleId={myRole.id}
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}

                  {required_show_length > 5 && (
                    <div className="info__chip">
                      {!isRequiredContainerExpanded && (
                        <div>Viser 5 av {required_show_length}</div>
                      )}
                      <button
                        type="button"
                        className={classnames('chip', 'show-more', {
                          'chip--active': isRequiredContainerExpanded,
                        })}
                        onClick={() =>
                          this.setState(({ isRequiredContainerExpanded }) => ({
                            isRequiredContainerExpanded: !isRequiredContainerExpanded,
                          }))
                        }>
                        ...
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="my-education-switch__section my-education-switch__section-option">
          {showOblComps.length !== 0 && (
            <div className="my-education__section__option-courses">
              <div className="content">
                <h2 className="my-education__section__title">
                  ANBEFALT KOMPETANSE
                </h2>
                {showOblComps.map(course => (
                  <div key={course.id}>
                    <div
                      className="my-education__section__role-wrapper__card"
                      key={course.id}>
                      <div
                        key={`competence-list-sub-${course.competence_id}`}
                        className="grid-x">
                        {(course.competence.competence_type.competence_type ===
                          'ecourse' && (
                          <CompetenceEcourse
                            type="ecourse"
                            size="big-optional"
                            isFetching={isSubFetching}
                            root="show-role"
                            course={course.competence}
                            roleId={role.id}
                          />
                        )) ||
                          (course.competence.competence_type.competence_type ===
                            'course' && (
                            <CompetenceCourse
                              type="course"
                              size="big-optional"
                              root="show-role"
                              course={course.competence}
                              roleId={role.id}
                            />
                          )) ||
                          (course.competence.competence_type.competence_type ===
                            'digital_signature' && (
                            <CompetenceSignature
                              type="signature"
                              size="big-optional"
                              root="show-role"
                              course={course.competence}
                              roleId={role.id}
                            />
                          )) ||
                          (course.competence.competence_type.competence_type ===
                            'complex' && (
                            <CompetenceComplex
                              isFetching={isSubFetching}
                              type="complex"
                              size="big-optional"
                              root="show-role"
                              course={course.competence}
                              roleId={role.id}
                            />
                          )) ||
                          (course.competence.competence_type.competence_type ===
                            'equivalents' && (
                            <CompetenceEquivalents
                              isFetching={isSubFetching}
                              type="equivalents"
                              size="big-optional"
                              root="show-role"
                              course={course.competence}
                              roleId={role.id}
                            />
                          )) ||
                          (course.competence.competence_type.competence_type ===
                            'verification' && (
                            <CompetenceVerification
                              type="verification"
                              size="big-optional"
                              root="show-role"
                              course={course.competence}
                              roleId={role.id}
                            />
                          )) ||
                          (course.competence.competence_type.competence_type ===
                            'checklist' && (
                            <CompetenceChecklist
                              type="checklist"
                              size="big-optional"
                              root="show-role"
                              course={course.competence}
                              roleId={role.id}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div>
          <div>
            <div>
              {!!myPassedCompetences.length && (
                <div className="my-education__section__role-passed">
                  <h2 className="my-education__section__title">
                    Dine ferdige kurs
                  </h2>

                  <div className="my-education__section__role-wrapper">
                    <div className="courses grid-x">
                      {showPassed.map(course => (
                        <div className="my-education__section__role-wrapper__card-small small-6 medium-4 cell">
                          <Link
                            to={getRouteWithParams(
                              myEducationRoutes.roleCourseView.path,
                              {
                                courseId: course.competence.id,
                                roleId: match.params.roleId,
                              }
                            )}
                            key={course.id}>
                            {(course.competence.cover && false !== true && (
                              <div
                                className="img"
                                style={{
                                  backgroundImage: `url(${
                                    course.competence.cover.url
                                  })`,
                                }}
                              />
                            )) || (
                              <div
                                className="img"
                                style={{
                                  backgroundImage: `url(${DEFAULT_BG})`,
                                }}
                              />
                            )}

                            <div className="content">
                              <div className="__title">
                                {course.competence.competence_type
                                  .competence_type && (
                                  <div>
                                    {course.competence.competence_type.title}
                                  </div>
                                )}
                                <h2>{course.competence.competence_title}</h2>
                              </div>
                            </div>
                          </Link>
                          {course.certificate_url && (
                            <a
                              className="__download-link"
                              href={`${backendUrl}${course.certificate_url}`}>
                              Last ned kursbevis
                            </a>
                          )}
                        </div>
                      ))}
                      {passed_length > 3 && (
                        <div className="info__chip">
                          {!isPassedContainerExpanded && (
                            <div>Viser 3 av {passed_length}</div>
                          )}
                          <button
                            type="button"
                            className={classnames('chip', 'show-more', {
                              'chip--active': isPassedContainerExpanded,
                            })}
                            onClick={() =>
                              this.setState(
                                ({ isPassedContainerExpanded }) => ({
                                  isPassedContainerExpanded: !isPassedContainerExpanded,
                                })
                              )
                            }>
                            ...
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MyEducationRoleContainer.propTypes = {
  role: PropTypes.shape({}).isRequired,
  isSubFetching: PropTypes.bool.isRequired,
  routerMyEducationRoleViewDidMount: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      cid: PropTypes.shape.isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = (...args) => ({
  isSubFetching: args[0].profile.competences.isSubFetching,
  role: args[0].profile.show_role,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerMyEducationRoleViewDidMount,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyEducationRoleContainer)
);
