import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import PersonAvatar from '@components/person-avatar/person-avatar';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { AutoSizer, List } from 'react-virtualized';
import { useOnClickOutside } from '../../hooks/hooks';

const SearchBox = ({
  input,
  meta,
  name,
  selectedItem,
  onChange,
  onSearch,
  labelField,
  avatar,
  loading,
  items,
  renderItem,
  renderItemText,
  renderDropdown,
  iconClassName,
  iconLeft,
  alwaysOpen,
  closeOnSelect,
  rowHeight,
  onOpen,
  onBlur: onBlurCallback,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(false);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const onBlur = useCallback(() => {
    setFocus(false);
    if (onBlurCallback) {
      onBlurCallback();
    }
  }, [inputRef.current, dropdownRef.current, onBlurCallback]);
  useOnClickOutside([inputRef, dropdownRef], onBlur);

  const onScroll = useCallback(() => {
    if (!inputRef.current || !dropdownRef.current) {
      return;
    }

    const { innerHeight } = window;
    const {
      left,
      width,
      bottom: top,
    } = inputRef.current.getBoundingClientRect();
    const { style } = dropdownRef.current;
    style.top = `${top + 4}px`;
    style.left = `${left - 1}px`;
    style.width = `${width + 2}px`;
    const maxHeight = Math.max(Math.min(600, innerHeight - top), 0);
    style.maxHeight = `${maxHeight - 8}px`;
    setDropdownHeight(maxHeight - 10);
  }, [inputRef.current, dropdownRef.current]);

  const onDropdownRef = ref => {
    dropdownRef.current = ref;
    onScroll();
  };

  useEffect(() => {
    document
      .getElementsByClassName('app-wrapper__content')[0]
      .addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);
    window.addEventListener('orientationchange', onScroll);
    onScroll();

    return () => {
      document
        .getElementsByClassName('app-wrapper__content')[0]
        .removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
      window.removeEventListener('orientationchange', onScroll);
    };
  }, [onScroll]);

  const defaultRenderItemText = renderItemText || (item => item[labelField]);

  const defaultRenderItem =
    renderItem ||
    ((item, index, props) => (
      <DropdownItem {...props}>{defaultRenderItemText(item)}</DropdownItem>
    ));

  const renderDropdownContents = () => (
    <AutoSizer style={{ height: dropdownHeight }}>
      {({ width }) => (
        <List
          width={width}
          height={dropdownHeight}
          rowHeight={rowHeight}
          rowCount={items.length}
          rowRenderer={({ key, style, index }) => (
            <div key={key} style={style}>
              {defaultRenderItem(items[index], index, {
                onClick: e => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (closeOnSelect) {
                    onBlur();
                  }
                  onChange(items[index]);
                },
                onKeyPress: e => e.key === 'Enter' && onChange(items[index]),
                tabIndex: '0',
                role: 'button',
                active: selectedItem === items[index],
              })}
            </div>
          )}
        />
      )}
    </AutoSizer>
  );

  const dropdownContents =
    (renderDropdown && renderDropdown(renderDropdownContents)) ||
    renderDropdownContents();

  const open = (alwaysOpen || focus) && !!dropdownContents;

  useEffect(() => {
    if (onOpen) {
      onOpen(open);
    }
  }, [open]);

  return (
    <Search open={open}>
      <SearchLabel htmlFor={input.name || name}>
        <SearchInput
          bold={selectedItem}
          autoComplete="off"
          type="text"
          {...props}
          ref={inputRef}
          id={input.name || name}
          name={input.name || name}
          onChange={({ target: { value } }) => {
            if (onSearch) {
              onSearch(value);
              setFocus(true);
            }
          }}
          onFocus={() => setFocus(true)}
          onClick={() => setFocus(true)}
          isFocus={focus}
          iconLeft={iconLeft}
        />
        {selectedItem && avatar && (
          <SearchAvatar>
            <PersonAvatar size="32px" fontSize="18px" person={selectedItem} />
          </SearchAvatar>
        )}
        <SearchIcon iconLeft={iconLeft} loading={loading}>
          {(loading && <i className=" fas fa-spin fa-spinner" />) || (
            <i className={classNames(iconClassName)} />
          )}
        </SearchIcon>
      </SearchLabel>
      {open && (
        <DropdownWrapper focus={focus} ref={onDropdownRef}>
          <Dropdown>{dropdownContents}</Dropdown>
        </DropdownWrapper>
      )}
      <input value={selectedItem} {...props} {...input} type="hidden" />
      {meta && meta.touched && meta.error && (
        <InputError>{meta.error}</InputError>
      )}
    </Search>
  );
};

SearchBox.propTypes = {
  input: PropTypes.shape({}),
  name: PropTypes.string,
  selectedItem: PropTypes.shape({}),
  avatar: PropTypes.bool,
  bold: PropTypes.bool,
  loading: PropTypes.bool,
  items: PropTypes.array,
  renderItem: PropTypes.func,
  renderItemText: PropTypes.func,
  renderDropdown: PropTypes.func,
  labelField: PropTypes.string,
  iconClassName: PropTypes.string,
  iconLeft: PropTypes.bool,
  alwaysOpen: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
  onOpen: PropTypes.func,
  onBlur: PropTypes.func,
};

SearchBox.defaultProps = {
  input: {},
  name: null,
  selectedItem: null,
  avatar: false,
  loading: false,
  bold: false,
  items: null,
  renderItem: null,
  renderItemText: null,
  renderDropdown: null,
  labelField: 'text',
  iconClassName: 'fa fa-search',
  iconLeft: false,
  alwaysOpen: false,
  closeOnSelect: false,
  onOpen: null,
  onBlur: null,
};

const SearchLabel = styled.label`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  cursor: text;
`;

const Search = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  display: inline;
  height: 100%;
  margin: auto 0;
  color: #999;
  line-height: 0;
  pointer-events: none;

  i {
    line-height: 0;
  }

  ${({ iconLeft }) =>
    (iconLeft &&
      css`
        left: 18px;
      `) ||
    css`
      right: 18px;
    `}

  ${({ loading }) =>
    loading &&
    css`
      i {
        color: #000;
      }
    `}
`;

const SearchInput = styled.input`
  flex: 1 0 auto;
  background-color: #dededc;
  border: none;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.8;
  border: 1px solid #dbd9d7;
  border-radius: 8px;
  overflow: hidden;
  transition: background-color 0.1s ease-out;
    
  ${({ iconLeft }) =>
    (iconLeft &&
      css`
        padding-left: 46px;
      `) ||
    css`
      padding-right: 46px;
    `}

  ${({ isFocus }) =>
    isFocus &&
    css`
      background-color: #fff;

      &::placeholder {
        color: #6a6868;
      }
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}
  ${({ inset }) =>
    inset &&
    css`
      padding-left: 50px !important;
    `}
    
  ::placeholder {
    color: #949393;
  }
`;

const SearchAvatar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  display: flex;
  align-items: center;
`;

const InputError = styled.div`
  color: #c22;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;

const DropdownWrapper = styled.div`
  position: fixed;
  display: flex;
  margin: 0;
  border: 1px solid #dbd9d7;
  border-radius: 8px;
  z-index: 100;
  transition: background-color 0.1s ease-out;
  overflow: hidden;

  ${({ focus }) =>
    focus &&
    css`
      background-color: #fff;
    `}
`;

const Dropdown = styled.div`
  display: block;
  flex: 1 1 auto;
  flex-direction: column;
`;

export const DropdownItem = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  margin: auto 0;
  padding: 10px 24px;
  line-height: 1.4;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;

      :hover {
        background-color: #eee;
      }
    `}

  ${({ active }) =>
    active &&
    css`
      font-weight: 700;
    `}
    
  :last-child {
    border-bottom: none;
  }
`;

export const DropdownItemAvatar = styled.div`
  position: relative;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  height: 100%;
  margin-right: 18px;
`;

export default SearchBox;
