import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import './badge-card.scss';

const BadgeCard = ({
  onClick,
  color,
  percentage,
  percentageColor,
  title,
  text,
}) => (
  <Card>
    <Icon color={color}>
      <IconText color={percentageColor}>{percentage}</IconText>
    </Icon>
    <Content>
      <Title onClick={onClick}>{title}</Title>
      <Text>{text}</Text>
    </Content>
  </Card>
);

const Card = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  min-height: 100px;
  box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
  margin: 10px 0;
  border: 1px solid #ccc;
  padding: 15px 25px;
  background: #fff;
`;

const Icon = styled.div`
  overflow: visible;
  display: flex;
  flex: 0 0 70px;
  justify-content: center;
  align-items: center;
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 100%;
  background-color: ${({ color }) => color};
`;
const IconText = styled.div`
  font-weight: 800;
  font-size: 120%;
  color: ${({ color }) => color};
`;
const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 25px;
  overflow: hidden;
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 0.5em;
  color: #6a6868;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ onClick }) =>
    onClick &&
    css`
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 0.3em;
      cursor: pointer;
    `}
`;
const Text = styled.div`
  font-size: 1em;
  color: #6a6868;
  line-height: 1.3;
`;

BadgeCard.propTypes = {
  color: PropTypes.string,
  percentage: PropTypes.string.isRequired,
  percentageColor: PropTypes.string,
  text: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

BadgeCard.defaultProps = {
  color: '#efeff0',
  percentageColor: '#7fc5cd',
  text: null,
  onClick: undefined,
};

export default BadgeCard;
