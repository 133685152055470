import {
  coursesSignCourse,
  coursesStartCourse,
  courseSignOn,
  coursesBeginSignature,
} from '@actions/courses.actions';
import { cheatCompetence } from '@actions/profile.actions';
import { employeesGet } from '@actions/employees.actions';
import { routerCourseCatalogCoursePreviewDidMount } from '@actions/router.actions';
import CourseDate from '@components/course/course-date';
import CourseButtons from '@components/course/course-buttons';
import EventsList from '@routes/employees/components/events-list';
import { getIsMobile } from '@selectors/global.selectors';

import {
  getCompetenceDetails,
  getSigningOnCourse,
  getSignOnCourseResults,
} from '@selectors/courses.selectors';

import { isManager, getEvents } from '@selectors/profile.selectors';

import {
  getEmployees,
  getIsFetchingEmployees,
  getEmployeesEvents,
} from '@selectors/employees.selectors';

import { fetchedDataWrapperShape } from '@types/fetched-data';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import AspectRatio from '@components/common/aspect-ratio';

import './course-details.scss';
import './course-dates.scss';
import CourseDuration from './course-duration';

const createDescriptionHTML = descriptionHTML => ({
  __html: descriptionHTML,
});

class CourseDetails extends Component {
  constructor(props) {
    super(props);
    this.onSignCourseClick = this.onSignCourseClick.bind(this);
    this.onStartCourseClick = this.onStartCourseClick.bind(this);
    this.onCheatClick = this.onCheatClick.bind(this);
  }

  componentDidMount() {
    const { routerCourseCatalogCoursePreviewDidMount, courseId } = this.props;
    routerCourseCatalogCoursePreviewDidMount({ cid: courseId });
  }

  onCheatClick(id) {
    const { cheatCompetence } = this.props;
    cheatCompetence({ id });
  }

  onStartCourseClick(cid, type) {
    const { coursesStartCourse } = this.props;
    coursesStartCourse({ cid, type });
  }

  onSignCourseClick(data) {
    const { coursesSignCourse } = this.props;
    coursesSignCourse(data);
  }

  render() {
    const {
      location,
      competenceDetails,
      events,
      isMobile,
      employeesEvents,
      courseSigningOn,
      coursesBeginSignature,
    } = this.props;

    const { action } = (location && location.state) || {};
    let my_events = null;
    if (events.data && competenceDetails.data) {
      my_events = events.data.filter(
        e => e.competence_id === competenceDetails.data.id
      );
    }

    let my_employees_events = null;
    if (employeesEvents && competenceDetails.data) {
      my_employees_events = employeesEvents.filter(
        ea => ea.competence_id === competenceDetails.data.id
      );
    }
    if (competenceDetails.data) {
      if (action === 'sign') {
        coursesBeginSignature(competenceDetails.data);
        location.state.action = null;
      }
      if (action === 'cheat') {
        this.onCheatClick(competenceDetails.data.id);
        location.state.action = null;
      }
      if (action === 'start_course') {
        this.onStartCourseClick(
          competenceDetails.data.id,
          competenceDetails.data.competence_type_id
        );
        location.state.action = null;
      }
    }

    return (
      <div className="course-preview">
        {(competenceDetails.isFetching && (
          <div className="course-preview__status">
            <i className="fa fa-spin fa-spinner" /> Laster...
          </div>
        )) ||
          (competenceDetails.error && (
            <div className="course-preview__status">
              Failed to load course details.
            </div>
          )) ||
          (competenceDetails.data && (
            <div className="course-preview__container">
              {competenceDetails.data.cover &&
                competenceDetails.data.cover.url && (
                  <AspectRatio
                    ratio={1024 / 400}
                    style={{
                      position: 'relative',
                    }}>
                    <div
                      className="course-preview__cover"
                      style={{
                        backgroundImage: `url(${
                          competenceDetails.data.cover.url
                        })`,
                        height: '100%',
                      }}
                    />
                  </AspectRatio>
                )}
              <div className="course-preview__header">
                <div className="course-preview__header-title-wrapper">
                  <div className="course-preview__header-title">
                    {competenceDetails.data.title}
                  </div>
                </div>

                {!isMobile && (
                  <div className="course-preview__header-meta-container">
                    {competenceDetails.data.duration && (
                      <CourseDuration
                        duration={competenceDetails.data.duration.value}
                        metric={competenceDetails.data.duration.metric}
                      />
                    )}
                  </div>
                )}
              </div>

              {isMobile && (
                <div className="course-preview__header-meta-container">
                  {competenceDetails.data.duration && (
                    <CourseDuration
                      duration={competenceDetails.data.duration.value}
                      metric={competenceDetails.data.duration.metric}
                    />
                  )}
                </div>
              )}

              <div className="course-preview__content">
                <div
                  className="course-preview__description"
                  dangerouslySetInnerHTML={createDescriptionHTML(
                    competenceDetails.data.description
                  )}
                />
                {competenceDetails.data.files && (
                  <div className="course-preview__files">
                    {competenceDetails.data.files.map(
                      ({ title, url }) =>
                        title !== 'cover' && (
                          <>
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn course-preview__file-button">
                              <i className="fas fa-file-alt" /> {title}
                            </a>
                          </>
                        )
                    )}
                  </div>
                )}
                <CourseButtons
                  {...this.props}
                  courseDetailsComponent={this}
                  course={competenceDetails.data}
                />
              </div>

              {competenceDetails.data.competence_type.competence_type_id ===
                3 && (
                <>
                  {my_events && my_events.length !== 0 && (
                    <>
                      <h3>Din påmelding</h3>
                      <EventsList type="employee" events={my_events} />
                    </>
                  )}
                  {my_employees_events && my_employees_events.length !== 0 && (
                    <>
                      <h3>Ansatte som er påmeldt</h3>
                      <EventsList events={my_employees_events} />
                    </>
                  )}
                </>
              )}
              {competenceDetails.data.competence_type.competence_type_id ===
                3 &&
                competenceDetails.data.events &&
                competenceDetails.data.events.length !== 0 && (
                  <div className="course-dates">
                    {(my_events && my_events.length !== 0 && (
                      <h3>Meld på andre</h3>
                    )) || <h3>Kursdatoer</h3>}
                    {competenceDetails.data.events.map(ce => (
                      <div className="course-dates__item" key={ce.id}>
                        {(my_events && (
                          <CourseDate
                            courseEvent={ce}
                            isMobile={isMobile}
                            usersCourseEvent={my_events.filter(
                              me => me.id === ce.id
                            )}
                            courseSigningOn={courseSigningOn}
                          />
                        )) || (
                          <CourseDate
                            isMobile={isMobile}
                            courseEvent={ce}
                            courseSigningOn={courseSigningOn}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              {competenceDetails.data.competence_type.competence_type_id ===
                16 &&
                competenceDetails.data.children &&
                competenceDetails.data.children.length !== 0 && (
                  <div>
                    {competenceDetails.data.children.map(course => (
                      <div className="course-preview__module" key={course.id}>
                        <div className="course-preview__module__title">
                          {course.title}
                        </div>
                        {course.files && !!course.files.length && (
                          <div className="course-preview__files">
                            {course.files.map(
                              ({ title, url }) =>
                                title !== 'cover' && (
                                  <>
                                    <a
                                      href={url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="btn course-preview__file-button">
                                      <i className="fas fa-file-alt" /> {title}
                                    </a>
                                  </>
                                )
                            )}
                          </div>
                        )}

                        <div className="course-preview__module__start">
                          <CourseButtons
                            {...this.props}
                            courseDetailsComponent={this}
                            course={course}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {competenceDetails.data.competence_type.competence_type_id ===
              6 &&
              competenceDetails.data.children &&
              competenceDetails.data.children.length !== 0 && (
                <div>
                  {competenceDetails.data.children.map(course => (
                    <div className="course-preview__module" key={course.id}>
                      <div className="course-preview__module__title">
                        {course.title}
                      </div>
                      {course.files && !!course.files.filter((c) => c.title !== 'cover').length && (
                        <div className="course-preview__files">
                          {course.files.map(
                            ({ title, url }) =>
                              title !== 'cover' && (
                                <>
                                  <a
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn course-preview__file-button">
                                    <i className="fas fa-file-alt" /> {title}
                                  </a>
                                </>
                              )
                          )}
                        </div>
                      )}
                      <div className="course-preview__module__start">
                        <CourseButtons
                          {...this.props}
                          courseDetailsComponent={this}
                          course={course}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}

            </div>
          ))}
      </div>
    );
  }
}

CourseDetails.propTypes = {
  competenceDetails: fetchedDataWrapperShape(PropTypes.shape({})).isRequired,
  activeCourse: PropTypes.shape({}),
  courseId: PropTypes.string.isRequired,
  passedCompetences: PropTypes.shape({}).isRequired,
  routerCourseCatalogCoursePreviewDidMount: PropTypes.func.isRequired,
  coursesStartCourse: PropTypes.func.isRequired,
  employeesActivities: PropTypes.func.isRequired,
  coursesSignCourse: PropTypes.func.isRequired,
  cheatCompetence: PropTypes.func.isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape({})),
  isFetchingEmployees: PropTypes.bool,
  isManager: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  employeesGet: PropTypes.func.isRequired,
  signOnCourseResults: PropTypes.arrayOf(
    PropTypes.shape({
      correct: PropTypes.arrayOf({
        message: PropTypes.string,
        user: PropTypes.string,
      }),
      errors: PropTypes.arrayOf({
        message: PropTypes.string,
        user: PropTypes.string,
      }),
    })
  ),
};

CourseDetails.defaultProps = {
  activeCourse: null,
  employees: [],
  isFetchingEmployees: false,
  signOnCourseResults: null,
};

const mapStateToProps = state => ({
  competenceDetails: getCompetenceDetails(state),
  courseSigningOn: getSigningOnCourse(state),
  isFetchingEmployees: getIsFetchingEmployees(state),
  isManager: isManager(state),
  events: getEvents(state),
  isMobile: getIsMobile(state),
  employees: getEmployees(state),
  employeesEvents: getEmployeesEvents(state),
  signOnCourseResults: getSignOnCourseResults(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerCourseCatalogCoursePreviewDidMount,
      coursesStartCourse,
      coursesSignCourse,
      cheatCompetence,
      courseSignOn,
      employeesGet,
      coursesBeginSignature,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CourseDetails)
);
