export const getCompetences = ({ courses: { competences } }) => ({
  ...competences,
  data:
    Array.isArray(competences.data) &&
    competences.data.map(competence => {
      if (!Array.isArray(competence.files)) {
        return competence;
      }
      const [cover] = competence.files.filter(file => file.title === 'cover');
      const durations =
        (competence.durations &&
          competence.durations.length &&
          competence.durations[0].duration) ||
        null; // ToDo: Get dynamic value from API
      const durationType =
        (competence.durations &&
          competence.durations.length &&
          competence.durations[0].type) ||
        null;
      return {
        ...competence,
        cover,
        durations,
        durationType,
      };
    }),
});

export const getCourseEvents = ({ courses: { courseEvents } }) => courseEvents;

export const getCompetencegroups = ({ courses: { competencegroups } }) =>
  competencegroups;

export const getCompetencetypes = ({ courses: { competencetypes } }) =>
  competencetypes;

export const getSelectedCatalogView = ({
  courses: {
    filters: { catalogView },
  },
}) => catalogView;

export const getSelectedCompetencegroupId = ({
  courses: { selectedCompetencegroupId },
}) => selectedCompetencegroupId;

export const getSelectedSubcompetencegroupId = ({
  courses: { selectedSubcompetencegroupId },
}) => selectedSubcompetencegroupId;

export const getSelectedSubSubcompetencegroupId = ({
  courses: { selectedSubSubcompetencegroupId },
}) => selectedSubSubcompetencegroupId;

export const getSigningOnCourse = ({ courses: { courseSignOn } }) =>
  courseSignOn;

export const getCompetencesSearchTerm = ({
  courses: {
    competences: { searchTerm },
  },
}) => searchTerm;

export const getCoursesSorting = ({ courses: { sorting } }) => sorting;

export const getSelectedCompetencetypes = ({
  courses: {
    filters: { selectedCompetencetypes },
  },
}) =>
  Object.keys(selectedCompetencetypes).reduce(
    (ids, id) => (selectedCompetencetypes[id] && [...ids, id]) || ids,
    []
  );

export const getSelectedCompetencetypesNames = ({
  courses: { competencetypes, selectedCompetencetypes },
}) =>
  competencetypes.data
    ? new Set(
        competencetypes.data
          .filter(({ id }) => selectedCompetencetypes.has(id))
          .map(({ competence_type }) => competence_type)
      )
    : new Set();

export const getCompetenceDetails = ({ courses: { competenceDetails } }) => ({
  ...competenceDetails,
  data:
    competenceDetails.data &&
    (competence => {
      let cover;
      let duration;
      if (
        Array.isArray(competence.files) &&
        competence.files.filter(file => file.title === 'cover').length
      ) {
        [cover] = competence.files.filter(file => file.title === 'cover');
      }
      if (Array.isArray(competence.durations) && competence.durations.length) {
        [duration] = competence.durations;
      }
      return {
        ...competence,
        cover,
        duration: duration && {
          value: duration.duration,
          metric: duration.type,
        },
      };
    })(competenceDetails.data),
});

export const getActiveCourse = ({ courses: { activeCourse } }) => activeCourse;

export const getSignOnCourseResults = ({
  courses: {
    courseSignOn: { results, isFetching },
  },
}) => ({ results, isFetching });

export const getCurrentSignature = ({ courses: { currentSignature } }) =>
  currentSignature;

export const getSignCourse = ({ courses: { courseSignCourse } }) =>
  courseSignCourse;
