import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { profileFetchPersonSummary } from '@actions/profile.actions';
import { getPassedCompetencesGroupedByType } from '@selectors/profile.selectors';

import { routerMyEducationMainViewDidMount } from '@actions/router.actions';

import '../styles/my-education-container.scss';
import { messagesGetMessages } from '@actions/messages.actions';

import { ProfileBaseInformation } from '@routes/my-education';
import NewsList from '@components/news-list/news-list';

class MyEducationContainer extends Component {
  componentDidMount() {
    const {
      routerMyEducationMainViewDidMount,
      profileFetchPersonSummary,
      messagesGetMessages,
    } = this.props;
    routerMyEducationMainViewDidMount();
    profileFetchPersonSummary();
    messagesGetMessages();
  }

  render() {
    return (
      <div className="my-education">
        <div className="my-education__section">
          <NewsList />
          <ProfileBaseInformation />
        </div>
      </div>
    );
  }
}

MyEducationContainer.propTypes = {
  passedCompetencesGroupedByType: PropTypes.shape({}).isRequired,
  requiredCompetences: PropTypes.shape({}).isRequired,
  routerMyEducationMainViewDidMount: PropTypes.func.isRequired,
  profileFetchPersonSummary: PropTypes.func.isRequired,
  messagesGetMessages: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  passedCompetencesGroupedByType: getPassedCompetencesGroupedByType(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerMyEducationMainViewDidMount,
      profileFetchPersonSummary,
      messagesGetMessages,
    },
    dispatch
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MyEducationContainer);
