import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  coursesSignCourse,
  coursesBeginSignature,
} from '@actions/courses.actions';
import { fetchedDataWrapperShape } from '@types/fetched-data';
import {
  getCurrentSignature,
  getSigningOnCourse,
} from '@selectors/courses.selectors';
import Modal from '@components/modal/modal';
import FormInput from '@components/form-input';
import { Field, reduxForm } from 'redux-form';

import FontAwesomeIcon from '@components/FontAwesomeIcon/FontAwesomeIcon';
import './signature-modal.scss';

const SignForm = reduxForm({
  form: 'sign-competence',
})(({ handleSubmit, onCancel }) => (
  <form onSubmit={handleSubmit} className="signature-modal">
    <Field component={FormInput} name="courseId" type="hidden" />
    <Field
      component={FormInput}
      label="Passord"
      name="password"
      type="password"
    />
    <div className="signature-modal__submit-button-wrapper">
      <button
        type="button"
        onClick={onCancel}
        className="btn btn--flat edit-pass__button">
        AVBRYT
      </button>
      <button className="btn" type="submit">
        Signer
      </button>
    </div>
  </form>
));

SignForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  courseId: PropTypes.number.isRequired,
};

const SignatureModal = ({
  coursesSignCourse,
  course,
  coursesBeginSignature,
}) => {
  return (
    course && (
      <>
        <Modal
          onBackdropClick={() => {
            coursesBeginSignature(null);
          }}>
          <div className="signature-modal">
            <div className="signature-modal__header">
              <FontAwesomeIcon icon="signature" text="Signer" />{' '}
              {course.title || course.competence_title}
            </div>
            <SignForm
              initialValues={{ courseId: course.id }}
              onSubmit={coursesSignCourse}
              onCancel={() => {
                coursesBeginSignature(null);
              }}
            />
          </div>
        </Modal>
      </>
    )
  );
};

SignatureModal.propTypes = {
  course: PropTypes.shape({}).isRequired,
  signedCourse: fetchedDataWrapperShape(PropTypes.shape({})).isRequired,
  signOnCourseResults: PropTypes.arrayOf(
    PropTypes.shape({
      correct: PropTypes.arrayOf({
        message: PropTypes.string,
        user: PropTypes.string,
      }),
      errors: PropTypes.arrayOf({
        message: PropTypes.string,
        user: PropTypes.string,
      }),
    })
  ),
  coursesBeginSignature: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  course: getCurrentSignature(state),
  signedCourse: state.courses.courseSignCourse,
  courseSigningOn: getSigningOnCourse(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      coursesSignCourse,
      coursesBeginSignature,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignatureModal);
