import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getFormSubmitErrors, stopSubmit } from 'redux-form';
import { Redirect } from 'react-router-dom';

import * as authActions from '@actions/auth.actions';

import AuthService from '../utils/auth-service';
import LoginForm from '../components/login-form';
import ChangePwdForm from '../components/login-newpwd';

import COOP_LOGO from '../../../assets/images/coop-pms-295.jpg';
import COOP_BG from '../../../assets/images/coop-kurs.jpg';

import '../styles/login-container.scss';

const isIE = false || !!document.documentMode;

class LoginContainer extends Component {
  state = {
    showSection: 'login',
  };

  handleForgot = ({ username }) => {
    const { authLoginNewPasswordRequest } = this.props;
    authLoginNewPasswordRequest({ username });
  };

  handleSelect = type => {
    const { authGotoNext } = this.props;
    authGotoNext(type);
  };

  handleLogin = ({ username, password }) => {
    const { authLoginRequest, stopSubmit } = this.props;
    authLoginRequest({ username, password });

    // Resets previous errrors in the login-form
    stopSubmit('login', {});
  };

  render() {
    const {
      sessionId,
      forgotStatus,
      forgotStatusMsg,
      secondLogin,
      submitErrors,
    } = this.props;
    const { showSection } = this.state;

    const loginFailure = submitErrors && submitErrors.errorMsg !== undefined;

    if (false === true && AuthService.isAuthorized(sessionId)) {
      return <Redirect to="/my-education" />;
    }

    if (forgotStatus === 2 && showSection === 'forgot') {
      this.setState({ showSection: 'login' });
    }

    return (
      <div className="login-page__container">
        <div className="grid-x">
          <div
            className="login-page__left img medium-6 columns hide-for-small-only"
            style={{
              backgroundImage: `url(${COOP_BG})`,
            }}>
            &nbsp;
            <div className="dark" />
          </div>

          <div className="small-12 medium-6 cell login-form">
            <div className="login_box">
              <img
                src={COOP_LOGO}
                alt="coop logo"
                className="logo"
                width={200}
              />
              <div className="text">
                {(secondLogin && sessionId && (
                  <div>
                    <h2>Gå til:</h2>
                    <button
                      type="button"
                      onClick={() => this.handleSelect('this')}>
                      Læringsportalen
                    </button>
                    <br />
                    <h3>eller</h3>
                    <button
                      type="button"
                      onClick={() => this.handleSelect('grape')}>
                      Avansert administrasjon
                    </button>
                  </div>
                )) || (
                  <>
                    <h2 className="login-page__title">
                      Velkommen til læringsportalen
                    </h2>

                    {isIE && (
                      <div className="red-warning">
                        Du er i en nettleser som ikke støttes. For å komme til
                        den nye Læringsportalen må du åpne en annen nettleser,
                        Chrome eller Edge, utenfor Citrix-miljøet.
                      </div>
                    )}

                    <div className="login-page__form">
                      {(forgotStatus === 1 && (
                        <div>
                          Fant ingen bruker.
                          <br />
                          Send e-post til{' '}
                          <a href="mailto:kompetanse@coop.no">
                            kompetanse@coop.no
                          </a>{' '}
                          – så hjelper vi deg
                        </div>
                      )) ||
                        (forgotStatus !== -1 && <div>{forgotStatusMsg}</div>)}
                      {(showSection === 'login' && (
                        <>
                          <LoginForm
                            initialValues={{
                              username: '',
                              password: '',
                            }}
                            onSubmit={this.handleLogin}
                            invalidCredentials={loginFailure}
                            handleCancel={() =>
                              this.setState({ showSection: 'forgot' })
                            }
                          />
                          <div className="faq">
                            <button
                              type="button"
                              onClick={() =>
                                this.setState({ showSection: 'faq' })
                              }>
                              Har du problemer med innloggningen? Se FAQ
                            </button>
                          </div>
                        </>
                      )) ||
                        (showSection === 'forgot' && (
                          <ChangePwdForm
                            initialValues={{
                              username: '',
                            }}
                            onSubmit={this.handleForgot}
                            handleCancel={() =>
                              this.setState({ showSection: 'login' })
                            }
                          />
                        )) ||
                        (showSection === 'faq' && (
                          <div>
                            <h2>FAQ</h2>
                            <div>
                              <strong>Norsk Butikkdrift</strong>
                              <br />
                              Bruk samme brukernavn og passord som tidligere
                              (www.rimiskolen.no).
                              <br />
                              <br />
                              <strong>Brukernavn</strong>
                              <br />
                              Alle brukernavn er:
                              <br /> samvirkelagsnummer + a + ansattnummer Eks.
                              13540a123456
                              <br />
                              <br />
                              Gamle brukernavn virker ikke i den nye
                              læringsportalen med mindre de var bygget opp som
                              eksemplet over.
                              <br />
                              <br />
                              <strong>Passord</strong>
                              <br />
                              Ditt gamle passord virker ikke i den nye
                              læringsportalen. Alle må endre passord før de
                              kommer inn i den nye læringsportalen. Du får ditt
                              første passord ved å benytte glemt
                              passord-funksjonen. Dersom du ikke får benyttet
                              glemt brukernavn/passord med din e-postadresse, be
                              din leder legge til din e-postadresse.
                              <br />
                              <br />
                              <strong>Epost</strong>
                              <br />
                              Systemet krever at det legges inn epostadresse ved
                              første gangs innlogging. Hvis du har Coop epost
                              skal denne legges inn. Har du ikke det skal det
                              legges inn privat epost. Personlig Coop epost eks:
                              per.pusling@coop.no Privat epost eks:
                              per.pusling@gmail.com NB! Ikke bruk butikkens
                              epost med mindre det er bare du som bruker denne.
                              Ikke bruk en annens epost, med mindre det er bare
                              du som bruker denne i læringsportalen.
                              <br />
                              <br />
                              <strong>Glemt passord</strong>
                              <br />
                              Har du allerede glemt det passordet du laget ved
                              første gangs innlogging må du bruke «glemt passord
                              funksjonen». Du vil da få tilsendt nytt passord på
                              den eposten du har registrert.
                              <br />
                              <br />
                              <strong>Butikksjefstilgang</strong>
                              <br />
                              Alle brukere i ny læringsportal er personlige. Det
                              betyr at du får tildelt rettigheter i forhold til
                              din stilling. Er du butikksjef vil du få
                              administratorrettigheter for din butikk.
                              <br />
                              <br />
                              <strong>Jobber i flere butikker</strong>
                              <br />
                              Alle skal bare ha en bruker i systemet. Jobber du
                              flere steder skal du fortsatt bare ha en
                              innlogging. Begge butikksjefer kan legge deg til
                              som ansatt i sin butikk.
                              <br />
                              <br />
                              <strong>
                                Andre spørsmål om ny læringsportal
                              </strong>
                              <br />
                              Kontakt HR funksjonen i ditt samvirkelag. For
                              Norsk Butikkdrift benyttes supportmailen
                              kompetanse@coop.no .
                              <br />
                              <br />
                              <strong>Jeg mangler ansatte i min butikk</strong>
                              <br />
                              Kontakt HR funksjonen i ditt samvirkelag.
                              Brukerveiledningen vil også vise deg hvordan du
                              finner ansatte i systemet og legger til din
                              butikk. Vær sikker på at personen ikke finnes i
                              systemet før du oppretter ny bruker.
                              <br />
                              <br />
                              <br />
                              <button
                                type="button"
                                className="back"
                                onClick={() =>
                                  this.setState({ showSection: 'login' })
                                }>
                                Tilbake til innlogging
                              </button>
                              <br />
                              <br />
                              <br />
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginContainer.propTypes = {
  sessionId: PropTypes.string,
  secondLogin: PropTypes.bool.isRequired,
  forgotStatus: PropTypes.number,
  forgotStatusMsg: PropTypes.string,
  authLoginRequest: PropTypes.func.isRequired,
  authGotoNext: PropTypes.func.isRequired,
  authLoginNewPasswordRequest: PropTypes.func.isRequired,
  submitErrors: PropTypes.shape({}),
  stopSubmit: PropTypes.func.isRequired,
};

LoginContainer.defaultProps = {
  sessionId: undefined,
  forgotStatus: -1,
  forgotStatusMsg: undefined,
  submitErrors: {},
};

const mapStateToProps = state => ({
  sessionId: state.auth.sessionId,
  forgotStatus: state.auth.newPwdStatus,
  secondLogin: state.auth.secondLogin,
  forgotStatusMsg: state.auth.newPwd,
  submitErrors: getFormSubmitErrors('login')(state),
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...authActions, stopSubmit }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
