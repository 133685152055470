import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@components/modal/modal';
import { coursesLmsRunning } from '@actions/courses.actions';
// import { getIsMobile } from '@selectors/global.selectors';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './course-player.scss';

const CoursePlayer = ({
  cid,
  type,
  onEnd,
  reloading,
  url,
  opened,
  // isMobile,
  coursesLmsRunning,
}) => (
  <Modal onBackdropClick={() => {}}>
    <div className="course-player">
      {type === 10 && (
        <div
          className={classNames('lms-dialog', {
            'lms-dialog--opened': opened,
          })}>
          <div className="lms-dialog__content">
            {(reloading && <div>Oppdaterer...</div>) ||
              ((opened && (
                <>
                  <div>
                    Når du har gjennomført e-kurset {'—'} trykk på{' '}
                    <i>gjennomført</i> for å gå tilbake.
                  </div>
                  <button type="button" onClick={onEnd} className="btn">
                    Gjennomført
                  </button>
                </>
              )) || (
                <>
                  <div>Netteleseren din er satt opp med Pop-up blokkering.</div>
                  <p>Trykk &laquo;Start e-kurs&raquo; for å åpne kurset.</p>
                  {/* <div>
                    For å kunne starte kurset automatisk må du deaktivere pop-up
                    blocker.
                  </div>
                  {(isMobile && (
                    <>
                      <p>
                        <b>iPhone/Safari:</b> Innstilinger &#10132; Safari
                        &#10132; Skru av &laquo; Blokker popupvinduer&raquo;
                      </p>
                      <p>
                        <b>Chrome:</b> Innstilinger &#10132;
                        Innholdsinnstillinger &#10132; Skru av &laquo;Blokker
                        forgrunnsvinduer&raquo;
                      </p>
                    </>
                  )) || (
                    <>
                      <p>
                        <b>Safari:</b> Innstilinger &#10132; Safari &#10132;
                        Skru av &laquo; Blokker popupvinduer&raquo;
                      </p>
                      <p>
                        <b>Chrome:</b> Innstilinger &#10132; Personvern og
                        sikkerhet &#10132; Nettsideinstillinger &#10132; Skru av
                        &laquo;Blokker forgrunnsvinduer&raquo;
                      </p>
                      <p>
                        <b>Edge:</b> Innstillinger &#10132; Nettstedstillatelser
                        &#10132; Skru av &laquo;Blokker forgrunnsvinduer&raquo;
                      </p>
                    </>
                  )} */}
                  <button
                    type="button"
                    onClick={() => {
                      const win = window.open(url, '_blank');
                      coursesLmsRunning({ url, opened: !!win });
                    }}
                    className="btn">
                    Start e-kurs
                  </button>
                </>
              ))}
          </div>
        </div>
      )}
      {type === 19 && (
        <div className="lms-dialog lms-dialog--opened">
          <div className="lms-dialog__content">
            <div>Klikk her for å signere</div>
            <button type="button" onClick={onEnd} className="btn">
              klar!
            </button>
          </div>
        </div>
      )}
      {type === 12 && (
        <iframe
          title="Course Player"
          src={`${
            process.env.REACT_APP_BACKEND_URL
          }/player/play/${cid}?type=${type}`}>
          Your browser doesnt support iframes
        </iframe>
      )}
    </div>
  </Modal>
);

CoursePlayer.propTypes = {
  cid: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  reloading: PropTypes.bool.isRequired,
  onEnd: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  opened: PropTypes.bool.isRequired,
  // isMobile: PropTypes.bool.isRequired,
  coursesLmsRunning: PropTypes.func.isRequired,
};

// const mapStateToProps = state => ({
//   isMobile: getIsMobile(state),
// });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      coursesLmsRunning,
    },
    dispatch
  );

export default connect(
  null, // mapStateToProps,
  mapDispatchToProps
)(CoursePlayer);
