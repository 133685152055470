import * as coursesActions from '@actions/courses.actions';

const initialState = {
  competences: {
    isFetching: false,
    data: null,
    error: null,
    isExpanded: false,
    searchTerm: '',
  },
  courseEvents: {
    isFetching: false,
    data: null,
    error: null,
  },
  selectedCompetencesIds: [],
  sorting: ['alpha', 'A-Å'],
  competencetypes: {
    isFetching: false,
    data: null,
    error: null,
  },
  currentSignature: null,
  competencegroups: {
    isFetching: false,
    data: null,
    error: null,
  },
  selectedCompetencegroupId: undefined,
  selectedSubcompetencegroupId: null,
  selectedSubSubcompetencegroupId: null,
  filters: {
    selectedCompetencetypes: {},
    catalogView: { tab: 'competences' },
  },
  competenceDetails: {
    isFetching: false,
    data: null,
    error: null,
  },
  activeCourse: null,
  courseSignOn: {
    isFetching: false,
    ceid: null,
    error: null,
    results: {
      errors: [],
      correct: [],
    },
  },
  courseSignCourse: {
    isFetching: false,
    ceid: null,
    error: null,
    success: false,
    status: '',
  },
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_STORE':
      return initialState;
    case coursesActions.COURSES_INITIALIZE_MY_COURSES_VIEW_REQUEST:
      return {
        ...state,
        competences: {
          isFetching: true,
          searchTerm: state.competences.searchTerm,
        },
        competencegroups: {
          isFetching: true,
        },
        competencetypes: {
          isFetching: true,
        },
      };
    case coursesActions.COURSES_GET_COMPETENCES_REQUEST:
      return {
        ...state,
        competences: {
          ...initialState.competences,
          isFetching: true,
          searchTerm: state.competences.searchTerm,
        },
      };
    case coursesActions.COURSES_GET_COMPETENCES_SUCCESS:
      return {
        ...state,
        competences: {
          ...initialState.competences,
          data: action.payload.competences,
          isExpanded: false,
          searchTerm: state.competences.searchTerm,
        },
      };
    case coursesActions.COURSES_SET_SEARCHTERM:
      return {
        ...state,
        competences: {
          ...state.competences,
          searchTerm: action.payload.searchTerm,
        },
      };
    case coursesActions.COURSES_SET_EXPAND:
      return {
        ...state,
        competences: {
          ...state.competences,
          isExpanded: true,
        },
      };
    case coursesActions.COURSES_GET_COMPETENCES_FAILURE:
      return {
        ...state,
        competences: {
          ...initialState.competences,
          error: action.payload.error,
          searchTerm: state.competences.searchTerm,
        },
      };
    case coursesActions.COURSES_GET_COURSEEVENTS_REQUEST:
      return {
        ...state,
        courseEvents: {
          ...initialState.courseEvents,
          isFetching: true,
        },
      };
    case coursesActions.COURSES_GET_COURSEEVENTS_SUCCESS:
      return {
        ...state,
        courseEvents: {
          ...initialState.courseEvents,
          data: action.payload.courseEvents,
        },
      };
    case coursesActions.COURSES_GET_COURSEEVENTS_FAILURE:
      return {
        ...state,
        courseEvents: {
          ...initialState.courseEvents,
          error: action.payload.error,
        },
      };
    case coursesActions.COURSES_BEGIN_SIGNATURE:
      return {
        ...state,
        currentSignature: action.payload,
      };
    case coursesActions.COURSES_GET_COMPETENCEGROUPS_REQUEST:
      return {
        ...state,
        competencegroups: {
          ...initialState.competencegroups,
          isFetching: true,
        },
      };
    case coursesActions.COURSES_GET_COMPETENCEGROUPS_SUCCESS:
      return {
        ...state,
        competencegroups: {
          ...initialState.competencegroups,
          data: action.payload.competencegroups,
        },
        selectedCompetencegroupId:
          (state.selectedCompetencegroupId === undefined &&
            action.payload.competencegroups.length &&
            action.payload.competencegroups[0].id) ||
          state.selectedCompetencegroupId,
      };
    case coursesActions.COURSES_GET_COMPETENCEGROUPS_FAILURE:
      return {
        ...state,
        competencegroups: {
          ...initialState.competencegroups,
          error: action.payload.error,
        },
      };
    case coursesActions.COURSES_FILTERS_SET_COMPETENCEGROUP:
      return {
        ...state,
        selectedCompetencegroupId: action.payload.competencegroupId,
        selectedSubcompetencegroupId: null,
        selectedSubSubcompetencegroupId: null,
      };
    case coursesActions.COURSES_FILTERS_SET_SUBCOMPETENCEGROUP:
      return {
        ...state,
        selectedSubcompetencegroupId:
          (state.selectedSubcompetencegroupId !==
            action.payload.subcompetencegroupId &&
            action.payload.subcompetencegroupId) ||
          null,
        selectedSubSubcompetencegroupId: null,
      };
    case coursesActions.COURSES_FILTERS_SET_SUB_SUBCOMPETENCEGROUP:
      return {
        ...state,
        selectedSubSubcompetencegroupId: action.payload.subSubcompetencegroupId,
      };
    case coursesActions.COURSES_FILTERS_SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...Object.keys(action.payload).reduce(
            (filters, key) => ({
              ...filters,
              [key]: {
                ...(state.filters[key] || {}),
                ...action.payload[key],
              },
            }),
            {}
          ),
        },
      };
    case coursesActions.COURSES_GET_COMPETENCETYPES_REQUEST:
      return {
        ...state,
        competencetypes: {
          ...initialState.competencetypes,
          isFetching: true,
        },
      };
    case coursesActions.COURSES_GET_COMPETENCETYPES_SUCCESS:
      return {
        ...state,
        competencetypes: {
          ...initialState.competencetypes,
          data: action.payload.competencetypes,
        },
      };
    case coursesActions.COURSES_GET_COMPETENCETYPES_FAILURE:
      return {
        ...state,
        competencetypes: {
          ...initialState.competencetypes,
          error: action.payload.error,
        },
      };
    case coursesActions.COURSES_FILTERS_TOGGLE_COMPETENCETYPE:
      return {
        ...state,

        selectedCompetencetypes: ((set, toggleElement) => {
          set[set.has(toggleElement) ? 'delete' : 'add'](toggleElement);
          return set;
        })(
          new Set(state.selectedCompetencetypes),
          action.payload.competencetypeId
        ),
      };
    case coursesActions.COURSES_GET_COMPETENCE_DETAILS_REQUEST:
      return {
        ...state,
        competenceDetails: {
          ...initialState.competenceDetails,
          isFetching: true,
          data: null,
          error: null,
        },
      };
    case coursesActions.COURSES_GET_COMPETENCE_DETAILS_SUCCESS:
      return {
        ...state,
        competenceDetails: {
          ...initialState.competenceDetails,
          isFetching: false,
          data: action.payload.competenceDetails,
          error: null,
        },
      };
    case coursesActions.COURSES_GET_COMPETENCE_DETAILS_FAILURE:
      return {
        ...state,
        competenceDetails: {
          ...initialState.competenceDetails,
          isFetching: false,
          data: null,
          error: action.payload.error,
        },
      };
    case coursesActions.COURSES_START_COURSE:
      return {
        ...state,
        activeCourse: {
          type: action.payload.type,
          cid: action.payload.cid,
          status: 0,
        },
      };
    case coursesActions.COURSES_RUN_COURSE:
      return {
        ...state,
        activeCourse: {
          type: state.activeCourse.type,
          cid: state.activeCourse.cid,
          url: action.payload.url,
          opened: action.payload.opened,
          status: 1,
        },
      };
    case coursesActions.COURSES_CLOSE_COURSE:
      return {
        ...state,
        activeCourse: null,
      };
    case coursesActions.COURSE_SIGNON_REQUEST:
      return {
        ...state,
        courseSignOn: {
          ...initialState.courseSignOn,
          isFetching: true,
          ceid: action.payload.ceid,
          results: null,
        },
      };
    case coursesActions.COURSE_SIGNON_SUCCESS:
      return {
        ...state,
        courseSignOn: {
          ...state.courseSignOn,
          isFetching: false,
          status: true,
        },
      };
    case coursesActions.COURSE_SIGNON_FAILURE:
      return {
        ...state,
        courseSignOn: {
          ...state.courseSignOn,
          isFetching: false,
          ceid: state.courseSignOn.ceid,
          error: action.payload.error,
        },
      };
    case coursesActions.COURSE_SIGNON_RESULTS:
      return {
        ...state,
        courseSignOn: {
          ...state.courseSignOn,
          results: action.payload.results,
          isFetching: false,
        },
      };
    case coursesActions.COURSES_SIGN_COURSE:
      return {
        ...state,
        courseSignCourse: {
          ...initialState.courseSignCourse,
          isFetching: true,
          ceid: action.payload.courseId,
        },
      };
    case coursesActions.COURSES_SIGN_COURSE_SUCCESS:
      return {
        ...state,
        courseSignCourse: {
          isFetching: false,
          success: true,
          status: action.payload.status,
        },
      };
    case coursesActions.COURSES_SIGN_COURSE_ERROR:
      return {
        ...state,
        courseSignCourse: {
          isFetching: false,
          error: action.payload.error,
        },
      };
    case coursesActions.COURSES_SET_SORTING:
      return {
        ...state,
        sorting: action.payload,
      };
    default:
      return state;
  }
};

export default profile;
