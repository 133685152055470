import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  coursesGetCourseEvents,
  coursesSetCompetencegroup,
  coursesSetFilters,
  coursesSetSearchTerm,
} from '@actions/courses.actions';
import {
  getCompetences,
  getCompetencesSearchTerm,
  getCompetencegroups,
  getSelectedCatalogView,
  getSelectedCompetencegroupId,
  getSelectedCompetencetypes,
} from '@selectors/courses.selectors';
import { fetchedDataWrapperShape } from '@types/fetched-data';
import { getIsMobile } from '@selectors/global.selectors';
import SearchBar from '@routes/employees/components/search-bar';

import CoursesMenu from '../courses-menu';

import './competencegroups-sidebar.scss';

const catalogViews = [
  ['competences', 'Kompetanse'],
  ['calendar', 'Kurskalender'],
];

const courseTypes = [['ecourse,complex', 'E-kurs'], ['course', 'Klassekurs']];

const CompetenceGroupSidebar = ({
  competences,
  catalogView,
  competencegroups,
  coursesGetCourseEvents,
  coursesSetFilters,
  coursesSetSearchTerm,
  coursesSetCompetencegroup,
  selectedCompetencegroupId,
  selectedCompetenceTypes,
  isMobile,
  searchTerm,
}) => {
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);

  const handleCourseTypeChange = ({ courseType, checked }) => {
    coursesSetFilters({
      selectedCompetencetypes: {
        [courseType]: checked,
      },
    });
  };

  const handleViewTabChange = ({ catalogView }) => {
    if (catalogView === 'calendar') {
      coursesGetCourseEvents();
    }
    coursesSetFilters({
      catalogView: { tab: catalogView },
    });
  };

  const handleCategoryChange = ({ competencegroupId }) => {
    setTimeout(() => setIsMobileFiltersOpen(false), 200);
    coursesSetCompetencegroup({
      competencegroupId,
    });
  };

  const disableButtons = catalogView.tab === 'calendar';

  return (
    <div
      className={classNames('competence-groups-sidebar', {
        'competence-groups-sidebar--mobile': isMobile,
        'competence-groups-sidebar__buttons-disabled': disableButtons,
      })}>
      <div className="competence-groups-sidebar__tabs">
        {catalogViews.map(([tabType, tabName], key) => (
          <div
            className={classNames('competence-groups-sidebar__tabs__tab', {
              active: catalogView.tab === tabType,
            })}
            key={key}
            role="button"
            tabIndex="0"
            onClick={() => handleViewTabChange({ catalogView: tabType })}
            onKeyPress={() => handleViewTabChange({ catalogView: tabType })}>
            {tabName}
          </div>
        ))}
      </div>
      {!disableButtons && (
        <>
          {isMobile && (
            <div className="competence-groups-sidebar__buttons">
              <button
                type="button"
                className={classNames([
                  'competence-groups-sidebar__filters-toggle',
                  {
                    'competence-groups-sidebar__filters-toggle--active': isMobileFiltersOpen,
                  },
                ])}
                onClick={() => setIsMobileFiltersOpen(!isMobileFiltersOpen)}>
                Kategorier
                <i className="fas fa-filter" />
              </button>
              <CoursesMenu isMobile={isMobile} />
            </div>
          )}
          <div
            className={classNames([
              'competence-groups-sidebar__filters',
              {
                'competence-groups-sidebar__filters--active': isMobileFiltersOpen,
              },
            ])}>
            {(!isMobile || isMobileFiltersOpen) && (
              <>
                <SearchBar
                  className="competence-groups-sidebar__competences-search"
                  placeholder="Søk kurs"
                  name="competence-search"
                  value={searchTerm}
                  loading={competences.isFetching}
                  onChange={({ target: { value } }) => {
                    coursesSetSearchTerm({ searchTerm: value });
                  }}
                />
                {(!searchTerm && (
                  <>
                    <div
                      className="competence-groups-sidebar__divider"
                      style={{
                        marginBottom: '16px',
                      }}
                    />
                    {Array.isArray(competencegroups.data) &&
                      competencegroups.data.map((option, key) => (
                        <CompetenceGroupsSidebarItem
                          key={key}
                          className="competence-groups-sidebar__item competence-groups-sidebar__item-category"
                          label={option.title}
                          name={option.id}
                          disabled={disableButtons}
                          onChange={() =>
                            handleCategoryChange({
                              competencegroupId: option.id,
                            })
                          }
                          type="radio"
                          checked={
                            !disableButtons &&
                            selectedCompetencegroupId === option.id
                          }
                        />
                      ))}
                  </>
                )) || (
                  <button
                    className="competence-groups-sidebar__clear-search"
                    type="button"
                    onClick={() => coursesSetSearchTerm({ searchTerm: '' })}>
                    <i className="fa fa-times" /> Fjern søk
                  </button>
                )}
                <div className="competence-groups-sidebar__divider" />
                <h3 className="competence-groups-sidebar__subtitle">
                  Kurs type
                </h3>
                <div className="competence-groups-sidebar__courses">
                  {courseTypes.map(([courseType, courseTypeName], key) => (
                    <CompetenceGroupsSidebarItem
                      key={key}
                      className="competence-groups-sidebar__item  competence-groups-sidebar__item-type"
                      label={courseTypeName}
                      name={courseType}
                      disabled={disableButtons}
                      onChange={() =>
                        handleCourseTypeChange({
                          courseType,
                          checked:
                            selectedCompetenceTypes.indexOf(courseType) === -1,
                        })
                      }
                      type="checkbox"
                      checked={
                        !disableButtons &&
                        selectedCompetenceTypes.indexOf(courseType) !== -1
                      }
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

CompetenceGroupSidebar.propTypes = {
  competencegroups: fetchedDataWrapperShape(
    PropTypes.arrayOf(PropTypes.shape({}))
  ).isRequired,
  catalogView: PropTypes.shape({}).isRequired,
  coursesGetCourseEvents: PropTypes.func.isRequired,
  coursesSetCompetencegroup: PropTypes.func.isRequired,
  selectedCompetenceTypes: PropTypes.array.isRequired,
  coursesSetFilters: PropTypes.func.isRequired,
  selectedCompetencegroupId: PropTypes.number,
  isMobile: PropTypes.bool.isRequired,
  competences: fetchedDataWrapperShape(PropTypes.arrayOf(PropTypes.shape({})))
    .isRequired,
  coursesSetSearchTerm: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

CompetenceGroupSidebar.defaultProps = {
  selectedCompetencegroupId: null,
};

const mapStateToProps = state => ({
  competencegroups: getCompetencegroups(state),
  catalogView: getSelectedCatalogView(state),
  selectedCompetencegroupId: getSelectedCompetencegroupId(state),
  selectedCompetenceTypes: getSelectedCompetencetypes(state),
  isMobile: getIsMobile(state),
  competences: getCompetences(state),
  searchTerm: getCompetencesSearchTerm(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      coursesGetCourseEvents,
      coursesSetCompetencegroup,
      coursesSetFilters,
      coursesSetSearchTerm,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetenceGroupSidebar);

const CompetenceGroupsSidebarItem = ({ className, name, label, ...props }) => (
  <label htmlFor={name} className={className}>
    <input autoComplete="off" id={name} name={name} {...props} />
    <div className="dott" />
    <div className="label">{label}</div>
  </label>
);

CompetenceGroupsSidebarItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

CompetenceGroupsSidebarItem.defaultProps = {
  className: '',
  label: '',
};
