import React from 'react';
import PropTypes from 'prop-types';

import Progress from '@components/progress/progress';
import PersonAvatar from '@components/person-avatar/person-avatar';

const PersonInfo = ({ profileData }) => (
  <div className="person">
    <div className="person__name">
      <h2>{`${profileData.firstname} ${profileData.lastname}`}</h2>
      <div className="person__education-and-focus-area-wrapper">
        <div className="person__education-status-container">
          <Progress
            progress={profileData.competencelevel}
            label="Læringsprogresjon"
          />
        </div>
      </div>
    </div>
    <div className="person__top-section">
      <div className="person__avatar-wrapper">
        <PersonAvatar
          person={profileData}
          className="person-avatar__avatar"
          editable
          size="80px"
        />
      </div>
    </div>
  </div>
);

PersonInfo.propTypes = {
  profileData: PropTypes.object.isRequired,
};

export default PersonInfo;
