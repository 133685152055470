import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { courseCatalog as courseCatalogRoutes } from '@routes/routes.manifest';

import { getRouteWithParams } from '@utils/routes.utils';

import * as coursesActions from '@actions/courses.actions';

import {
  getSelectedCatalogView,
  getCompetences,
  getCourseEvents,
  getCompetencegroups,
  getSelectedCompetencegroupId,
  getSelectedSubcompetencegroupId,
  getCoursesSorting,
  getCompetencesSearchTerm,
} from '@selectors/courses.selectors';
import { getIsMobile } from '@selectors/global.selectors';

import { getEvents } from '@selectors/profile.selectors';

import { fetchedDataWrapperShape } from '@types/fetched-data';

import moment from 'moment';

import 'moment/locale/nb';

import './course-catalog-container.scss';

import CourseCard, {
  CourseCardLoader,
} from './components/course-card/course-card';

import CourseCalendarCard, {
  CourseCalendarCardLoading,
} from './components/course-calendar-card/course-calendar-card';

import CoursesMenu from './components/courses-menu/courses-menu';

import CompetenceGroupSidebar from './components/courses-menu/competencegroups-list/competencegroups-sidebar';
import CoursesMenuChipses from './components/courses-menu-chipses/courses-menu-chipses';

const getCourseType = type =>
  ({
    verification: 'Verifikasjon',
    courseevent: 'Klasseromskurs',
    ecourse: 'E-kurs',
    complex: 'E-kurs gruppe',
    equivalents: 'Kompeteanser',
  }[type] || type);

class CourseCatalogContainer extends Component {
  state = {
    isExpanded: false,
  };

  constructor(props) {
    super(props);

    this.handleSorting = this.handleSorting.bind(this);
    this.sortDuration = this.sortDuration.bind(this);
    this.handleShowMore = this.handleShowMore.bind(this);
    this.handleCompetenceChipsClick = this.handleCompetenceChipsClick.bind(
      this
    );
  }

  componentDidMount() {
    const { coursesInitializeMyCoursesView } = this.props;
    this.setState({ isExpanded: false });
    coursesInitializeMyCoursesView();
  }

  handleCompetenceChipsClick = competencegroupId => {
    const { coursesFiltersSetSubcompetencegroup } = this.props;
    /* reset expanded flag */
    coursesFiltersSetSubcompetencegroup({
      subcompetencegroupId: competencegroupId,
    });
  };

  sortDuration(a, b) {
    const typeA = a.durationType;
    const typeB = b.durationType;
    let durationA = a.durations;
    let durationB = b.durations;

    if (!durationA) {
      durationA = 1000000;
    } else if (typeA === 'hours') {
      durationA *= 60;
    } else if (typeA === 'days') {
      durationA *= 60 * 24;
    }

    if (!durationB) {
      durationB = 1000000;
    } else if (typeB === 'hours') {
      durationB *= 60;
    } else if (typeB === 'days') {
      durationB *= 60 * 24;
    }
    if (durationA < durationB) {
      return 1;
    }
    return -1;
  }

  handleShowMore() {
    console.log('click more. ');
    const { coursesExpand } = this.props;
    coursesExpand();
  }

  handleSorting(list) {
    const { sorting } = this.props;

    switch (sorting[0]) {
      case 'type':
        return list.sort((a, b) => a.competence_type_id - b.competence_type_id);
      case 'date':
        return list.sort((a, b) => {
          return (
            new Date(a.modified).getTime() - new Date(b.modified).getTime()
          );
        });
      case 'alphabetical':
        return list.sort((a, b) => a.title - b.title);
      case 'duration':
        // Reverse list so longest courses come first
        return list.sort(this.sortDuration).reverse();
      default:
        return list;
    }
  }

  renderCalendar = (list, events) => {
    list.sort((a, b) => {
      return new Date(a.modified).getTime() - new Date(b.modified).getTime();
    });
    let currentMonth = null;
    return list.reduce((list, item) => {
      let my_events = [];
      if (events && events.data) {
        my_events = events.data.filter(
          e => e.competence_id === item.competence_id
        );
      }
      const itemComponent = (
        <div className="course-catalog__course-calendar-card-wrapper">
          <CourseCalendarCard
            event={item}
            href={getRouteWithParams(courseCatalogRoutes.coursePreview.path, {
              cid: item.competence_id,
            })}
            usersCourseEvent={my_events.filter(me => me.id === item.id)}
          />
        </div>
      );
      const month = moment(item.startdate).format('MMMM');
      if (month !== currentMonth) {
        currentMonth = month;
        return [
          ...list,
          <h2 className="course-catalog__calendar-title-month">{month}</h2>,
          itemComponent,
        ];
      }
      return [...list, itemComponent];
    }, []);
  };

  render() {
    const {
      competences,
      competencegroups,
      catalogView,
      events,
      courseEvents,
      isMobile,
      selectedCompetencegroupId,
      selectedSubcompetencegroupId,
      searchTerm,
    } = this.props;

    const { isExpanded } = this.state;

    return (
      <div className="course-catalog">
        <CompetenceGroupSidebar />
        {isMobile && catalogView.tab === 'competences' && (
          <CoursesMenuChipses
            competencegroups={competencegroups}
            handleCompetenceChipsClick={this.handleCompetenceChipsClick}
            isMobile={isMobile}
            selectedCompetencegroupId={selectedCompetencegroupId}
            selectedSubcompetencegroupId={selectedSubcompetencegroupId}
          />
        )}
        <div className="course-catalog__courses-wrapper">
          {catalogView.tab === 'competences' && !isMobile && <CoursesMenu />}
          {(catalogView.tab === 'competences' && (
            <>
              <div className="course-catalog__courses">
                {(competences.isFetching &&
                  Array(5)
                    .fill()
                    .map((_, i) => (
                      <div
                        key={i}
                        className="course-catalog__course-card-wrapper">
                        <CourseCardLoader />
                      </div>
                    ))) ||
                  this.handleSorting(competences.data || [])
                    .slice(0, competences.isExpanded ? 100 : 9)
                    .map(
                      ({
                        id,
                        title,
                        course_type,
                        competence_type,
                        short_description,
                        cover,
                        durations,
                        durationType,
                      }) => (
                        <div
                          className="course-catalog__course-card-wrapper"
                          key={id}>
                          <CourseCard
                            title={title}
                            competence_type={
                              competence_type || getCourseType(course_type)
                            }
                            description={short_description}
                            duration={
                              (durations &&
                                durations.length &&
                                durations[0] &&
                                durations[0].duration) ||
                              durations
                            }
                            cover={cover}
                            type={
                              durationType ||
                              (durations &&
                                durations.length &&
                                durations[0] &&
                                durations[0].type)
                            }
                            href={getRouteWithParams(
                              courseCatalogRoutes.coursePreview.path,
                              {
                                cid: id,
                              }
                            )}
                          />
                        </div>
                      )
                    )}
                {(competences.data.length > 9 && !competences.isExpanded && (
                  <div className="info__chip">
                    <div className="grid-x">
                      <div className="cell small-12">
                        <button
                          type="button"
                          className={classnames('chip', 'show-more', {
                            'chip--active': isExpanded,
                          })}
                          onClick={this.handleShowMore}>
                          ...
                        </button>
                      </div>
                    </div>
                  </div>
                )) ||
                  (!competences.isFetching &&
                    !competences.data.length &&
                    searchTerm && (
                      <div className="course-catalog__no-results">
                        Søket{' '}
                        <span className="course-catalog__no-results__term">
                          {searchTerm}
                        </span>{' '}
                        ga ingen treff
                      </div>
                    ))}
              </div>
            </>
          )) ||
            (catalogView.tab === 'calendar' && (
              <div className="course-catalog__courseevents-wrapper">
                {(courseEvents.isFetching && (
                  <div className="course-catalog__course-calendar-card-wrapper">
                    <h2 className="course-catalog__calendar-title-month">
                      &nbsp;
                    </h2>
                    <CourseCalendarCardLoading />
                    <CourseCalendarCardLoading />
                    <CourseCalendarCardLoading />
                  </div>
                )) ||
                  this.renderCalendar(courseEvents.data || [], events)}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

CourseCatalogContainer.propTypes = {
  competences: fetchedDataWrapperShape(PropTypes.arrayOf(PropTypes.shape({})))
    .isRequired,
  competencegroups: fetchedDataWrapperShape(
    PropTypes.arrayOf(PropTypes.shape({}))
  ).isRequired,
  courseEvents: fetchedDataWrapperShape(PropTypes.arrayOf(PropTypes.shape({})))
    .isRequired,
  coursesFiltersSetSubcompetencegroup: PropTypes.func.isRequired,
  coursesExpand: PropTypes.func.isRequired,
  sorting: PropTypes.string.isRequired,
  catalogView: PropTypes.shape({}).isRequired,
  events: PropTypes.shape({}).isRequired,
  coursesInitializeMyCoursesView: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  selectedCompetencegroupId: PropTypes.number,
  selectedSubcompetencegroupId: PropTypes.object.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

CourseCatalogContainer.defaultProps = {
  selectedCompetencegroupId: null,
};

const mapStateToProps = state => ({
  sorting: getCoursesSorting(state),
  catalogView: getSelectedCatalogView(state),
  competences: getCompetences(state),
  competencegroups: getCompetencegroups(state),
  courseEvents: getCourseEvents(state),
  isMobile: getIsMobile(state),
  events: getEvents(state),
  selectedCompetencegroupId: getSelectedCompetencegroupId(state),
  selectedSubcompetencegroupId: getSelectedSubcompetencegroupId(state),
  searchTerm: getCompetencesSearchTerm(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...coursesActions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseCatalogContainer);
