import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router';
import { Route, Redirect, Switch, Link } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { getRouteWithParams } from '@utils/routes.utils';
import ActivityList, {
  ActivityListLoading,
} from '@routes/employees/components/activity-list';
import classNames from 'classnames';
import reports from '@routes/reports.manifest';

import {
  getEmployees,
  getIsFetchingEmployees,
  getSearchPhraze,
  getSelectedPerson,
  getEmployeesStatistics,
  getReport,
  getEmployeesActivities,
  getSelectedPersonReport,
  getFunctions,
  getEmployeesChecklists,
  getIsFetchingFunctions,
  getEvents,
} from '@selectors/employees.selectors';

import { getRoles, getOrganisations } from '@selectors/roles.selectors';
import {
  getMainOrganisations,
  getProfile,
  getOrganisationId,
} from '@selectors/profile.selectors';
import { getIsMobile } from '@selectors/global.selectors';

import { PersonShape } from '@types/person';
import { RoleShape, OrganisationShape } from '@types/role';

import { routerEmployeesMainViewDidMount } from '@actions/router.actions';
import {
  employeesAddPerson,
  employeesEditSelectedPerson,
  employeesUpdateSelectedPersonRoles,
  employeesUpdateSelectedPersonEmployment,
  employeesFetchSelectedPerson,
  employeesFetchSelectedPersonReport,
  employeesFetchFunctions,
  employeesEditViewInit,
  employeesReportViewInit,
  campExtraViewInit,
  employeesAddViewInit,
  employeesResetPassword,
  employeesEditPassword,
  employeesSaveVerification,
  employeesSaveRequirements,
  employeesSaveRole,
} from '@actions/employees.actions';
import { messagesSendMessage } from '@actions/messages.actions';
import { fetchRoles } from '@actions/roles.actions';

import '@routes/employees/styles/employees-container.scss';
import '@routes/employees/styles/employees-link-card.scss';

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import StatisticCard, {
  StatisticCardLoading,
} from '@routes/employees/components/statistic-card';
import EmployeeAdd from '@routes/employees/components/employee-add';
import EmployeePreview from '@routes/employees/components/employee-preview';
import EmployeesReport from '@routes/employees/components/employees-report';
import EmployeeReport from '@routes/employees/components/employee-report';
import EmployeeEdit from '@routes/employees/components/employee-edit';
import EmployeeMessage from '@routes/employees/components/employee-message';
import EmployeeCard, {
  EmployeeCardLoading,
} from '@routes/employees/components/employee-card';

import { fetchedDataWrapperShape } from '@types/fetched-data';

import EmployeesListContainer from '@routes/employees/containers/employees-list-container';
import EventsList from '@routes/employees/components/events-list';
import EmployeesChecklist from '@routes/employees/components/employees-checklist';
import MobileReport from '@routes/employees/components/mobile-report';

import { employees as routes } from '@routes/routes.manifest';

import {
  getIsSuperuser,
  getIsDriftsjef,
} from '../../../store/selectors/profile.selectors';

const breadcrumbsRoutes = [
  {
    breadcrumb: 'Dashboard',
    path: routes.dashboard,
  },
  {
    breadcrumb: 'Mine ansatte',
    path: routes.employees,
  },
  {
    breadcrumb: 'Legg til ansatt',
    path: routes.addEmployee,
  },
  {
    breadcrumb: 'Ansatt',
    path: routes.employeePreview,
  },
  {
    breadcrumb: 'Rediger',
    path: routes.employeeEdit,
  },
  {
    breadcrumb: 'Melding',
    path: routes.employeeMessage,
  },
  {
    breadcrumb: 'Rapport',
    path: routes.reportPreview,
  },
  {
    breadcrumb: 'Camp Extra',
    path: routes.campExtra,
  },
];

class EmployeesContainer extends Component {
  employeePreview = React.createRef();

  employeeEdit = React.createRef();

  state = {
    isMobileChecklistOpen: false,
    isMyAccessOpen: false,
    isMobilePersonnelOpen: false,
  };

  componentDidMount() {
    const { routerEmployeesMainViewDidMount } = this.props;

    routerEmployeesMainViewDidMount();
  }

  toggleMobileChecklist = () => {
    this.setState(state => ({
      isMobileChecklistOpen: !state.isMobileChecklistOpen,
    }));
  };

  toggleMyAccessOpen = () => {
    this.setState(state => ({ isMyAccessOpen: !state.isMyAccessOpen }));
  };

  toggleMobilePersonnel = () => {
    this.setState(state => ({
      isMobilePersonnelOpen: !state.isMobilePersonnelOpen,
    }));
  };

  handleSendMessage = message => {
    const { messagesSendMessage } = this.props;

    messagesSendMessage({
      ...message,
      callback: this.redirectToPreviewEmployee,
    });
  };

  handleCancelMessage = () => {
    const {
      history: { push },
      selectedPerson: {
        data: { user_name: userName },
      },
    } = this.props;

    push(
      getRouteWithParams(routes.employeePreview, {
        userName,
      })
    );
  };

  changeOrg = org => {
    localStorage.setItem('orgId', org);
    window.location.href = `/dashboard/${org}`;
  };

  redirectToEmployees = () => {
    const {
      history: { push },
      orgId,
    } = this.props;

    push(
      getRouteWithParams(routes.employees, {
        orgId,
      })
    );
  };

  redirectToPreviewEmployee = ({ employee }) => {
    const {
      history: { push },
    } = this.props;
    const { user_name } = employee;

    push(
      getRouteWithParams(routes.employeePreview, {
        userName: user_name,
      })
    );

    this.forceUpdate();
  };

  handleEditEmployeeClick = employee => {
    const {
      history: { push },
    } = this.props;
    const { user_name: userName } = employee;

    const urlWithParams = getRouteWithParams(routes.employeeEdit, {
      userName,
    });
    push(urlWithParams);
  };

  handleMessageEmployeeClick = employee => {
    const {
      history: { push },
    } = this.props;
    const { user_name: userName } = employee;

    const urlWithParams = getRouteWithParams(routes.employeeMessage, {
      userName,
    });
    push(urlWithParams);
  };

  handleAddEmployeeFormSubmit = employee => {
    const { employeesAddPerson } = this.props;

    employeesAddPerson({
      employee,
      callback: this.redirectToPreviewEmployee,
    });
  };

  handleEmploymentFormSubmit = ({ data }) => {
    const {
      employeesUpdateSelectedPersonEmployment,
      selectedPerson,
    } = this.props;
    employeesUpdateSelectedPersonEmployment({
      personId: selectedPerson.data.id,
      data,
      callback: ({ quitted }) => quitted && this.redirectToEmployees(),
    });
  };

  handleEditEmployeeFormSubmit = employee => {
    const { employeesEditSelectedPerson } = this.props;
    employeesEditSelectedPerson({
      person: employee,
      callback: this.redirectToPreviewEmployee,
    });
  };

  handleEmployeeRolesFormSubmit = ({ roles }) => {
    const { employeesUpdateSelectedPersonRoles } = this.props;
    employeesUpdateSelectedPersonRoles({
      roles,
    });
  };

  handleEmployeeReportViewInit = userName => {
    console.log('start me up scortty');
    console.log(userName);
    const { employeesFetchSelectedPersonReport } = this.props;
    employeesFetchSelectedPersonReport({ userName });
  };

  handleEmployeePreviewInit = userName => {
    const { employeesFetchSelectedPerson } = this.props;
    employeesFetchSelectedPerson({ userName });
  };

  handleEmployeeMessageInit = userName => {
    const { employeesFetchSelectedPerson, selectedPerson } = this.props;

    if (!selectedPerson.data) {
      employeesFetchSelectedPerson({ userName });
    }
  };

  handleEmployeesReportInit = reportId => {
    const { employeesReportViewInit } = this.props;
    employeesReportViewInit({
      reportId,
    });
  };

  handleCampExtraInit = () => {
    const { campExtraViewInit } = this.props;
    campExtraViewInit();
  };

  handleEmployeeEditViewInit = userName => {
    const { employeesEditViewInit } = this.props;
    employeesEditViewInit({
      userName,
    });
  };

  handleAddEmployeeViewInit = userName => {
    const { employeesAddViewInit, employeesFetchFunctions } = this.props;
    employeesAddViewInit({
      userName,
    });
    employeesFetchFunctions();
  };

  handleResetPassword = identifier => {
    const { employeesResetPassword } = this.props;

    employeesResetPassword({ identifier });
  };

  handleEditPassword = data => {
    const { employeesEditPassword } = this.props;
    employeesEditPassword({ data, callback: this.redirectToPreviewEmployee });
  };

  render() {
    const {
      breadcrumbs,
      functions,
      events,
      isFetchingFunctions,
      isMobile,
      location,
      report,
      roles,
      person,
      organisations,
      mainOrganisation,
      statistics,
      selectedPerson,
      activities,
      checklists,
      employeeReport,
      employeesSaveVerification,
      employeesSaveRequirements,
      employeesSaveRole,
      isSuperuser,
      isDriftsjef,
    } = this.props;

    const {
      isMobileChecklistOpen,
      isMyAccessOpen,
      isMobilePersonnelOpen,
    } = this.state;

    const TaskListPreview =
      this.employeePreview.current && this.employeePreview.current.TasksList;
    const TaskListEdit =
      this.employeeEdit.current && this.employeeEdit.current.TasksList;

    return (
      <div
        className={classNames('employees-page', {
          'report-page': matchPath(location.pathname, {
            path: routes.employeeReport,
            exact: false,
          }),
        })}>
        {!matchPath(location.pathname, {
          path: routes.employees,
          exact: true,
        }) &&
          !matchPath(location.pathname, {
            path: routes.dashboard,
            exact: true,
          }) && (
            <div className="employees-page__section">
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
          )}
        <Switch>
          <Route
            path={routes.dashboard}
            exact
            render={() => (
              <>
                <h1>
                  {mainOrganisation ? mainOrganisation.title : <>&nbsp;</>}
                </h1>
                <div className="employees-page__grid-wrapper">
                  <div className="employees-page__statistics-wrapper">
                    {!isMobile && (
                      <div className="employees-page__statistics">
                        <Link
                          to={getRouteWithParams(routes.reportPreview, {
                            reportId: Object.keys(reports)[0],
                          })}
                          className="employees-page__statistics-card-wrapper">
                          {(statistics.data && (
                            <StatisticCard
                              name="Totalt"
                              progress={statistics.data.progress}
                              clickable
                            />
                          )) || <StatisticCardLoading />}
                        </Link>

                        {statistics.data &&
                          statistics.data.functions.map((statistic, key) => (
                            <>
                              <Link
                                key={key}
                                to={getRouteWithParams(routes.reportPreview, {
                                  reportId: statistic.role_id,
                                })}
                                className="employees-page__statistics-card-wrapper">
                                <StatisticCard
                                  name={statistic.title}
                                  progress={statistic.progress}
                                  clickable
                                />
                              </Link>
                            </>
                          ))}
                      </div>
                    )}
                    {checklists.data && checklists.data.length !== 0 && (
                      <>
                        {(isMobile && (
                          <div className="employees-page__mobile-checklist">
                            <button
                              className="employees-page__mobile-toggle"
                              type="button"
                              onClick={this.toggleMobileChecklist}>
                              <span>Sjekkliste punkter</span>
                              <span>({checklists.data.length})</span>
                            </button>
                            {isMobileChecklistOpen && (
                              <div className="employees-page__activity-wrapper">
                                <EmployeesChecklist
                                  type="employees"
                                  items={checklists.data}
                                />
                              </div>
                            )}
                          </div>
                        )) || (
                          <>
                            <h2>Sjekkliste punkter</h2>
                            <div className="employees-page__activity-wrapper">
                              <EmployeesChecklist
                                type="employees"
                                items={checklists.data}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {events &&
                      events.length !== 0 &&
                      (isMobile ? (
                        <div className="employees-page__mobile-personnel">
                          <button
                            className="employees-page__mobile-toggle"
                            type="button"
                            onClick={this.toggleMobilePersonnel}>
                            <span>Ansatte som skal på kurs</span>
                            <span>({events.length})</span>
                          </button>
                          {isMobilePersonnelOpen && (
                            <div className="employees-page__activity-wrapper">
                              <EventsList events={events} />
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <h2>Ansatte som skal på kurs</h2>
                          <div className="employees-page__activity-wrapper">
                            <EventsList events={events} />
                          </div>
                        </>
                      ))}

                    <h2>Finn ansatt</h2>
                    <div className="employees-page__activity-wrapper">
                      <EmployeesListContainer searchOnly />
                    </div>

                    <h2>Aktiviteter</h2>
                    <div className="employees-page__activity-wrapper">
                      {(activities && activities.data && (
                        <ActivityList activities={activities.data} />
                      )) ||
                        (activities.isFetching && <ActivityListLoading />)}
                    </div>
                  </div>

                  <div className="employees-page__left-wrapper">
                    {isMobile && (
                      <>
                        <div className="employees-page__statistics employees-page__statistics--mobile">
                          <Link
                            to={getRouteWithParams(routes.reportPreview, {
                              reportId: Object.keys(reports)[0],
                            })}
                            className="employees-page__statistics-card-wrapper">
                            {(statistics.data && (
                              <StatisticCard
                                name="Totalt"
                                progress={statistics.data.progress}
                                clickable
                              />
                            )) || <StatisticCardLoading />}
                          </Link>
                          {statistics.data &&
                            statistics.data.functions.map((statistic, key) => (
                              <Link
                                key={key}
                                to={getRouteWithParams(routes.reportPreview, {
                                  reportId: statistic.role_id,
                                })}
                                className="employees-page__statistics-card-wrapper">
                                <StatisticCard
                                  name={statistic.title}
                                  progress={statistic.progress}
                                  clickable
                                />
                              </Link>
                            ))}
                        </div>
                        <MobileReport
                          routes={routes}
                          statistics={statistics}
                          showCampExtra={isSuperuser || isDriftsjef}
                          mainOrganisation={mainOrganisation}
                        />
                      </>
                    )}
                    <div className="employees-page__report-wrapper">
                      {!isMobile && (
                        <>
                          <h2>Rapporter - søk</h2>
                          <Link
                            className="employees-page__users-overview-wrapper"
                            to={routes.reportBrandschool}>
                            <div className="employees-link-card">
                              <div className="employees-link-card__label">
                                Obligatorisk kompetanse
                              </div>
                            </div>
                          </Link>
                          {/* <Link
                              className="employees-page__users-overview-wrapper"
                              to={routes.reportSearch}>
                              <div className="employees-link-card">
                                <div className="employees-link-card__label">
                                  Søk kompetanse
                                </div>
                              </div>
                            </Link> */}
                          {(isSuperuser || isDriftsjef) && (
                            <Link
                              className="employees-page__users-overview-wrapper"
                              to={routes.campExtra}>
                              <div className="employees-link-card">
                                <div className="employees-link-card__label">
                                  Camp Extra
                                </div>
                              </div>
                            </Link>
                          )}
                          <div css="margin-bottom: 40px;" />
                          <h2>Oversikts matriser</h2>
                          <Link
                            className="employees-page__users-overview-wrapper"
                            to={getRouteWithParams(routes.reportPreview, {
                              reportId: Object.keys(reports)[0],
                            })}>
                            <div className="employees-link-card employees-link-card--light">
                              <div className="employees-link-card__label">
                                All kompetanse
                              </div>
                            </div>
                          </Link>

                          {statistics.data &&
                            statistics.data.functions.map(statistic => (
                              <Link
                                className="employees-page__users-overview-wrapper"
                                to={getRouteWithParams(routes.reportPreview, {
                                  reportId: statistic.role_id,
                                })}>
                                <div className="employees-link-card employees-link-card--light">
                                  <div className="employees-link-card__label">
                                    {statistic.title}
                                  </div>
                                </div>
                              </Link>
                            ))}
                        </>
                      )}

                      {person.organisations && person.organisations.length > 1 && (
                        <div className="employees-page__users-access-wrapper">
                          {!isMobile && <br />}
                          <br />
                          <button
                            type="button"
                            onClick={this.toggleMyAccessOpen}>
                            <div className="employees-link-card employees-link-card--light">
                              <div className="employees-link-card__label">
                                Tilganger ({person.organisations.length})
                              </div>
                            </div>
                          </button>
                          {isMyAccessOpen && (
                            <ul>
                              {person.organisations
                                .sort((a, b) => (a.title > b.title ? 1 : -1))
                                .map(org => (
                                  <li>
                                    <button
                                      type="button"
                                      onKeyDown={() => this.changeOrg(org.id)}
                                      onClick={() => this.changeOrg(org.id)}>
                                      {org.title}
                                    </button>
                                  </li>
                                ))}
                            </ul>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          />
          <Route
            path={routes.employees}
            exact
            render={() => (
              <>
                <Link to={routes.dashboard}>
                  <h1>
                    {mainOrganisation ? mainOrganisation.title : <>&nbsp;</>}
                  </h1>
                </Link>
                <h2>Ansatte</h2>
                <EmployeesListContainer />
              </>
            )}
          />
          <Route
            path={routes.reportPreview}
            exact
            render={({
              match: {
                params: { reportId },
              },
            }) => (
              <div className="employees-page__section">
                <EmployeesReport
                  report={report}
                  onInit={() => this.handleEmployeesReportInit(reportId)}
                />
              </div>
            )}
          />
          <Route
            path={routes.addEmployee}
            render={() => (
              <div className="employees-page__section">
                <EmployeeAdd
                  roles={roles}
                  functions={functions}
                  organisations={organisations}
                  onInit={() => this.handleAddEmployeeViewInit()}
                  onAddEmployeeFormSubmit={this.handleAddEmployeeFormSubmit}
                  initialValues={{
                    position:
                      functions.position[0] && functions.position[0].title,
                    send_login: true,
                  }}
                />
              </div>
            )}
          />
          <Route
            path={routes.employeeReport}
            render={({
              match: {
                params: { userName },
              },
            }) => (
              <div className="employees-page__section">
                <EmployeeReport
                  report={employeeReport}
                  onInit={() => this.handleEmployeeReportViewInit(userName)}
                />
              </div>
            )}
          />
          <Route
            path={routes.employeePreview}
            exact
            render={({
              match: {
                params: { userName },
              },
            }) => (
              <div
                className={classNames([
                  'employees-page__section',
                  'employees-page__section--columns',
                  'employees-page__section--preview',
                ])}>
                {(selectedPerson.data && (
                  <>
                    <EmployeeCard
                      isExpanded
                      employee={selectedPerson.data}
                      taskList={TaskListPreview}
                      onProfileClick={this.handleCancelMessage}
                      onEditClick={this.handleEditEmployeeClick}
                      onMessageClick={this.handleMessageEmployeeClick}
                      isMobile={isMobile}
                    />
                  </>
                )) ||
                  (selectedPerson.isFetching && (
                    <EmployeeCardLoading isMobile={isMobile} isExpanded />
                  ))}
                <EmployeePreview
                  ref={this.employeePreview}
                  onInit={() => this.handleEmployeePreviewInit(userName)}
                  onEditEmployeeClick={this.handleEditEmployeeClick}
                  onMessageEmployeeClick={this.handleMessageEmployeeClick}
                  onRoleFormSubmit={this.handleEmployeeRolesFormSubmit}
                  onEmploymentFormSubmit={this.handleEmploymentFormSubmit}
                  saveVerification={employeesSaveVerification}
                  saveRequirements={employeesSaveRequirements}
                  saveRole={employeesSaveRole}
                />
              </div>
            )}
          />
          <Route
            path={routes.employeeEdit}
            render={({
              match: {
                params: { userName },
              },
            }) => (
              <div
                className={classNames([
                  'employees-page__section',
                  'employees-page__section--columns',
                  'employees-page__section--edit',
                ])}>
                {(selectedPerson.data && (
                  <EmployeeCard
                    isExpanded
                    page="edit"
                    onProfileClick={this.handleCancelMessage}
                    employee={selectedPerson.data}
                    taskList={TaskListEdit}
                    onEditClick={this.handleEditEmployeeClick}
                    onMessageClick={this.handleMessageEmployeeClick}
                  />
                )) ||
                  (selectedPerson.isFetching && (
                    <EmployeeCardLoading isMobile={isMobile} isExpanded />
                  ))}
                <EmployeeEdit
                  ref={this.employeeEdit}
                  person={selectedPerson}
                  roles={roles}
                  organisations={organisations}
                  isFetching={isFetchingFunctions}
                  onInit={() => this.handleEmployeeEditViewInit(userName)}
                  onEmployeeFormSubmit={this.handleEditEmployeeFormSubmit}
                  onResetPassword={this.handleResetPassword}
                  onEditPassword={this.handleEditPassword}
                />
              </div>
            )}
          />
          <Route
            path={routes.employeeMessage}
            render={({
              match: {
                params: { userName },
              },
            }) => (
              <div
                className={classNames([
                  'employees-page__section',
                  'employees-page__section--columns',
                  'employees-page__section--message',
                ])}>
                {(selectedPerson.data && (
                  <EmployeeCard
                    isExpanded
                    page="message"
                    onProfileClick={this.handleCancelMessage}
                    employee={selectedPerson.data}
                    onEditClick={this.handleEditEmployeeClick}
                    onMessageClick={this.handleMessageEmployeeClick}
                  />
                )) ||
                  (selectedPerson.isFetching && (
                    <EmployeeCardLoading isMobile={isMobile} isExpanded />
                  ))}
                <EmployeeMessage
                  username={userName}
                  onSendMessage={this.handleSendMessage}
                  onInit={() => this.handleEmployeeMessageInit(userName)}
                  onCancel={this.handleCancelMessage}
                />
              </div>
            )}
          />
          <Redirect to={routes.dashboard} />
        </Switch>
      </div>
    );
  }
}

EmployeesContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  selectedPerson: PropTypes.shape({
    userName: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    data: PersonShape(),
    error: PropTypes.shape({}),
  }).isRequired,
  roles: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(RoleShape()),
    error: PropTypes.shape({}),
  }).isRequired,
  report: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.object,
    error: PropTypes.shape({}),
  }).isRequired,
  organisations: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(OrganisationShape()),
    error: PropTypes.shape({}),
  }).isRequired,
  mainOrganisation: PropTypes.shape({
    organisation_id: PropTypes.number.isRequired,
    extern_organisation_id: PropTypes.number,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }),
  functions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.shape({})),
    positions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  employeeReport: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  person: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checklists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetchingFunctions: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  statistics: fetchedDataWrapperShape(PropTypes.shape({})).isRequired,
  activities: fetchedDataWrapperShape(PropTypes.arrayOf(PropTypes.shape({})))
    .isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  routerEmployeesMainViewDidMount: PropTypes.func.isRequired,
  employeesAddPerson: PropTypes.func.isRequired,
  employeesEditSelectedPerson: PropTypes.func.isRequired,
  employeesUpdateSelectedPersonRoles: PropTypes.func.isRequired,
  employeesUpdateSelectedPersonEmployment: PropTypes.func.isRequired,
  employeesFetchFunctions: PropTypes.func.isRequired,
  employeesFetchSelectedPerson: PropTypes.func.isRequired,
  employeesFetchSelectedPersonReport: PropTypes.func.isRequired,
  employeesEditViewInit: PropTypes.func.isRequired,
  employeesAddViewInit: PropTypes.func.isRequired,
  employeesReportViewInit: PropTypes.func.isRequired,
  employeesResetPassword: PropTypes.func.isRequired,
  employeesEditPassword: PropTypes.func.isRequired,
  campExtraViewInit: PropTypes.func.isRequired,
  messagesSendMessage: PropTypes.func.isRequired,
  employeesSaveVerification: PropTypes.func.isRequired,
  employeesSaveRequirements: PropTypes.func.isRequired,
  employeesSaveRole: PropTypes.func.isRequired,
  orgId: PropTypes.number.isRequired,
  isSuperuser: PropTypes.bool.isRequired,
  isDriftsjef: PropTypes.bool.isRequired,
};

EmployeesContainer.defaultProps = {
  mainOrganisation: null,
  events: null,
};

const mapStateToProps = state => ({
  employees: getEmployees(state),
  person: getProfile(state),
  selectedPerson: getSelectedPerson(state),
  isFetchingEmployees: getIsFetchingEmployees(state),
  isMobile: getIsMobile(state),
  searchPhraze: getSearchPhraze(state),
  roles: getRoles(state),
  functions: getFunctions(state),
  events: getEvents(state),
  employeeReport: getSelectedPersonReport(state),
  isFetchingFunctions: getIsFetchingFunctions(state),
  report: getReport(state),
  organisations: getOrganisations(state),
  mainOrganisation: getMainOrganisations(state),
  statistics: getEmployeesStatistics(state),
  checklists: getEmployeesChecklists(state),
  activities: getEmployeesActivities(state),
  orgId: getOrganisationId(state),
  isSuperuser: getIsSuperuser(state),
  isDriftsjef: getIsDriftsjef(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerEmployeesMainViewDidMount,
      employeesAddPerson,
      employeesEditSelectedPerson,
      employeesUpdateSelectedPersonRoles,
      employeesUpdateSelectedPersonEmployment,
      employeesFetchSelectedPerson,
      employeesFetchSelectedPersonReport,
      employeesFetchFunctions,
      employeesEditViewInit,
      employeesReportViewInit,
      employeesResetPassword,
      employeesEditPassword,
      campExtraViewInit,
      employeesAddViewInit,
      messagesSendMessage,
      fetchRoles,
      employeesSaveVerification,
      employeesSaveRequirements,
      employeesSaveRole,
    },
    dispatch
  );

export default compose(
  withRouter,
  withBreadcrumbs(breadcrumbsRoutes, {
    disableDefaults: true,
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EmployeesContainer);
